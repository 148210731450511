import { 
  FETCH_AVAILABLE_TEAMS_APPLICATIONS_BEGIN,FETCH_AVAILABLE_TEAMS_APPLICATIONS_SUCCESS,FETCH_AVAILABLE_TEAMS_APPLICATIONS_FAILURE,
  FETCH_TEAM_APPLICATION_QUERY_BEGIN, FETCH_TEAM_APPLICATION_QUERY_SUCCESS, FETCH_TEAM_APPLICATION_QUERY_FAILURE,
  FETCH_PLAYER_APPLICATIONS_BEGIN, FETCH_PLAYER_APPLICATIONS_SUCCESS, FETCH_PLAYER_APPLICATIONS_FAILURE,
  DECLINE_APPLICATION_BEGIN, DECLINE_APPLICATION_SUCCESS, DECLINE_APPLICATION_FAILURE,
  ACCEPT_APPLICATION_BEGIN, ACCEPT_APPLICATION_SUCCESS, ACCEPT_APPLICATION_FAILURE,
} from '../constants/action-types';

const initialState = {
  teamsApplications: {},
  teamFullApplication: {},
  playerApplications:{},
  loading: false,
  playerLoading:false,
  declineLoading: false,
  acceptLoading: false,
  error: null
};

function applicationsReducer(state = initialState, action) {
  switch(action.type) {
    case ACCEPT_APPLICATION_BEGIN:
      return {
        ...state,
        acceptLoading: true,
        error: null
      };

    case ACCEPT_APPLICATION_SUCCESS:
      return {
        ...state,
        acceptLoading: false,
        teamsApplications: action.payload.applications
      };

    case ACCEPT_APPLICATION_FAILURE:
      return {
        ...state,
        acceptLoading: false,
        error: action.payload.error,
        teamsApplications: {}
      };

    case DECLINE_APPLICATION_BEGIN:
      return {
        ...state,
        declineLoading: true,
        error: null
      };

    case DECLINE_APPLICATION_SUCCESS:
      return {
        ...state,
        declineLoading: false,
        teamsApplications: action.payload.applications
      };

    case DECLINE_APPLICATION_FAILURE:
      return {
        ...state,
        declineLoading: false,
        error: action.payload.error,
        teamsApplications: {}
      };

    case FETCH_PLAYER_APPLICATIONS_BEGIN:
      return {
        ...state,
        playerLoading: true,
        error: null
      };

    case FETCH_PLAYER_APPLICATIONS_SUCCESS:
      return {
        ...state,
        playerLoading: false,
        playerApplications: action.payload.applications
      };

    case FETCH_PLAYER_APPLICATIONS_FAILURE:
      return {
        ...state,
        playerLoading: false,
        error: action.payload.error,
        playerApplications: {}
      };

    case FETCH_TEAM_APPLICATION_QUERY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_TEAM_APPLICATION_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        teamFullApplication: action.payload.application
      };

    case FETCH_TEAM_APPLICATION_QUERY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        teamFullApplication: {}
      };


    case FETCH_AVAILABLE_TEAMS_APPLICATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_AVAILABLE_TEAMS_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamsApplications: action.payload.applications
      };

    case FETCH_AVAILABLE_TEAMS_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        teamsApplications: {}
      };
  
      default:
        return state;
    }
};

export default applicationsReducer;