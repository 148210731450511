import React, { useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "../utilities/auth";
import SideBar from "../components/SideBar";
import AdminHeader from "../components/AdminHeader"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {logOut, removeRefreshToken} from '../actions/usersActions'
import {API_URLS} from '../constants/config'


function PrivateRoute({ component: Component, ...rest }) {
  const history = useHistory();
  const { userData } = useAuth();

  const { setUserData } = useAuth();
  const [isBarCollapsed, setIsBarCollapsed] = useState(true);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const data = JSON.parse(localStorage.getItem("userData"));

  const logOut = () => {
    rest.actions.removeRefreshToken(API_URLS.logout);
    rest.actions.logOut();
    setUserData();
  }

  const toggleBar = () => {
    setIsBarCollapsed(!isBarCollapsed)
  }

  const toggleDropDown = () => {
    setDropDownVisible(!dropDownVisible)
  }

  const goTo = (route) => {
    history.push(route);

  }

  return (
    <Route
      {...rest}
      render={props =>
        userData ? (
          <div className="adminPage">
          <SideBar collapsed={isBarCollapsed} goTo={goTo}/>
          <div style={{width: '100%'}}>
            <AdminHeader 
              logOut={logOut} 
              toggleDropDown={toggleDropDown} 
              dropDownVisible={dropDownVisible} 
              userData={data} 
              toggleSideBar={toggleBar}/>
              <div className="pageWrapper">
          <Component {...props} />
          </div>
          </div>
          </div>
        ) : (
          <Redirect
          to={{ pathname: "/", state: { referer: props.location } }}
        />
        )
      }
    />
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({logOut, removeRefreshToken}, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);