import { FETCH_BLACK_LIST_BEGIN, FETCH_BLACK_LIST_SUCCESS , FETCH_BLACK_LIST_FAILURE,
    FETCH_BLACK_LIST_PLAYER_BEGIN, FETCH_BLACK_LIST_PLAYER_SUCCESS, FETCH_BLACK_LIST_PLAYER_FAILURE,
    ADD_TO_BLACK_LIST_BEGIN, ADD_TO_BLACK_LIST_SUCCESS, ADD_TO_BLACK_LIST_FAILURE,
    DELETE_FROM_BLACK_LIST_BEGIN, DELETE_FROM_BLACK_LIST_SUCCESS, DELETE_FROM_BLACK_LIST_FAILURE,
    CHANGE_BLACK_LIST_BEGIN, CHANGE_BLACK_LIST_SUCCESS, CHANGE_BLACK_LIST_FAILURE} from '../constants/action-types';

  
  const initialState = {
    list: [],
    player: {},
    loading: false,
    error: null
  };
  
  export default function blackListReducer(state = initialState, action) {
    switch(action.type) {

        case DELETE_FROM_BLACK_LIST_BEGIN:
            return {
              ...state,
              loading: true,
              error: null
            };
      
          case DELETE_FROM_BLACK_LIST_SUCCESS:
              const index_delete = state.list.data.findIndex(record => record.id == action.payload.blacklist_player.data.id)
              if(index_delete != -1) state.list.data.splice(index_delete,1);
            return {
              ...state,
              loading: false,
            };
      
          case DELETE_FROM_BLACK_LIST_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.payload.error,
            };

        case CHANGE_BLACK_LIST_BEGIN:
            return {
              ...state,
              loading: true,
              error: null
            };
      
          case CHANGE_BLACK_LIST_SUCCESS:
              const index_change = state.list.data.findIndex(record => record.id == action.payload.blacklist_player.data.id)
              if(index_change != -1) state.list.data[state.list.data.findIndex(record => record.id == action.payload.blacklist_player.data.id)] = action.payload.blacklist_player.data;
            return {
              ...state,
              loading: false,
              player: action.payload.blacklist_player
            };
      
          case CHANGE_BLACK_LIST_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.payload.error,
            };

        case ADD_TO_BLACK_LIST_BEGIN:
            return {
              ...state,
              loading: true,
              error: null
            };
      
          case ADD_TO_BLACK_LIST_SUCCESS:
            if(action.payload.blacklist_player.data && action.payload.blacklist_player.data.length){
              state.list.data.unshift(...action.payload.blacklist_player.data)
            } else {
              state.list.data.unshift(action.payload.blacklist_player.data)
            }
            return {
              ...state,
              loading: false,
              player: action.payload.blacklist_player
            };
      
          case ADD_TO_BLACK_LIST_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.payload.error,
            };

        case FETCH_BLACK_LIST_PLAYER_BEGIN:
            return {
              ...state,
              loading: true,
              error: null
            };
      
          case FETCH_BLACK_LIST_PLAYER_SUCCESS:
            return {
              ...state,
              loading: false,
              player: action.payload.blacklist_player
            };
      
          case FETCH_BLACK_LIST_PLAYER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.payload.error,
              player: {}
            };
  
      case FETCH_BLACK_LIST_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case FETCH_BLACK_LIST_SUCCESS:

        // const temp_state = state.list;
        // if(temp_state.data && temp_state.data.length && temp_state.data.length > 0){
        //     temp_state.data = temp_state.data.concat(action.payload.blacklist.data);
        // } else {
        //     temp_state = action.payload.blacklist
        // }
        return {
          ...state,
          loading: false,
          list: action.payload.blacklist
        };
  
      case FETCH_BLACK_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          list: []
        };
  
      default:
        return state;
    }
  }