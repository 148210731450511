import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultTeamLogo from "../images/defaultTeamLogo.png";
import { API_URLS, BASE_URL } from "../constants/config";
import Loader from "react-loader-spinner";
import moment from 'moment'
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {fetchBlackList, deleteFromBlackList, addToBlackList} from '../actions/blacklistActions';
import {searchPlayers} from '../actions/playersActions'
import {fetchAdminTeams} from '../actions/teamsActions'
import {fetchRoster} from '../actions/teamsActions'
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import SearchBar from '../components/SearchBar';
import BlackListPlayer from "../components/BlackListPlayer";
import CustomModal from "../components/CustomModal";
import SearchPlayersSideBar from '../components/SearchPlayersSideBar';


export default function BlackList(props) {
    const black_list = useSelector(state => state.blacklist.list.data);
    const loading = useSelector(state => state.blacklist.loading);
    const error = useSelector(state => state.blacklist.error);
    const dispatch = useDispatch();
    const [collapsedPlayersids,setCollapsedPlayersids] = useState([]);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deletePlayerId, setDeletePlayerId] = useState(false);
    //const [searchPlayers, setSearchPlayerEnabled] = useState(false);
    const searchPlayersData = useSelector(state => state.player.searchPlayers.data);
    const searchTeamsData = useSelector(state => state.team.adminTeams.data);
    const searchPlayersLoading = useSelector(state => state.player.loading);
    const [tempRoster,setTempRoster] = useState(false);
    const [applicationList, setApplicationList] = useState([]);


  

    useEffect(() => {
      _loadAsyncBlackList()
    }, []);

    const _loadAsyncBlackList = (search) => {
        let url = API_URLS.getBlackList;
      dispatch(fetchBlackList(url,{search})).then(
        list => {

        }
    );
    };

    const searchInput = (input) => {
      _loadAsyncBlackList(input)

    }

    const addPlayerToBlackList = (data) => {
      if(data && data.player_id){
        let url = API_URLS.addToBlackList;
        if(!data.player_id.length){
          dispatch(addToBlackList(url,{playerId:data.player_id, comment: data.reason, price: data.price})).then(
            data  => {
              // setDeletePlayerId(false)
              // setDeleteModalVisible(false)
            }
        );
        } else if(data.player_id.length && data.player_id.length > 1){
          dispatch(addToBlackList(url,{playerIds:data.player_id, comment: data.reason, price: data.price})).then(
            data  => {
              // setDeletePlayerId(false)
              // setDeleteModalVisible(false)
            }
        );
        }

      } else {
        setDeleteModalVisible(false)
      }
  
    }

  const collapsePlayer = (id) => {
      const className = 'black_list_player_collapsible' + id
      const classNameTwo = 'black_list_player_' + id
      const classNameThree = 'black_list_player_menu_' + id
      const classNameFour = 'black_list_player_menu_cross_' + id
      const collapsedPlayersidsTemp = collapsedPlayersids
      const index = collapsedPlayersidsTemp.findIndex(player_id => player_id == id)
      if(index != -1){
        document.getElementById(className).classList.remove('ready_to_big');
        document.getElementById(className).classList.add('ready_to_small_it');
        document.getElementById(classNameTwo).classList.add('blacklistPlayerContainerBrightness');
        document.getElementById(classNameThree).classList.remove('blackListPlayerTopMenuWrapperHidden');
        document.getElementById(classNameFour).classList.remove('blackListPlayerCollapsibleButtonOpacityAdd');
        collapsedPlayersidsTemp.splice(index,1);
        setCollapsedPlayersids((state) => [...collapsedPlayersidsTemp])

      } else {
        document.getElementById(className).classList.remove('ready_to_small_it');
        document.getElementById(className).classList.add('ready_to_big');
        document.getElementById(classNameThree).classList.add('blackListPlayerTopMenuWrapperHidden');
        document.getElementById(classNameTwo).classList.remove('blacklistPlayerContainerBrightness');
        document.getElementById(classNameFour).classList.add('blackListPlayerCollapsibleButtonOpacityAdd');
        setCollapsedPlayersids((state) => [...state,id])

      }
  }
  
  const searchPlayersWidgetToggle = (action) => {
    if(action === 'open'){
      document.getElementById('blacklistAddPlayersPageWrapper').classList.remove('blacklistAddPlayersPageWrapperClose');
      document.getElementById('blacklistAddPlayersPageWrapper').classList.add('blacklistAddPlayersPageWrapperOpen');

      document.getElementById('blacklistAddPlayersCloseButton').classList.remove('blackListPlayerCollapsibleButtonOpacityRemove');
      document.getElementById('blacklistAddPlayersCloseButton').classList.add('blackListPlayerCollapsibleButtonOpacityAdd');
    } else {
      document.getElementById('blacklistAddPlayersPageWrapper').classList.remove('blacklistAddPlayersPageWrapperOpen');
      document.getElementById('blacklistAddPlayersPageWrapper').classList.add('blacklistAddPlayersPageWrapperClose');

      document.getElementById('blacklistAddPlayersCloseButton').classList.remove('blackListPlayerCollapsibleButtonOpacityAdd');
      document.getElementById('blacklistAddPlayersCloseButton').classList.add('blackListPlayerCollapsibleButtonOpacityRemove');
    }


  }

  const deletePlayers = () => {
    if(deletePlayerId){
      let url = API_URLS.deleteFromBlackList;
      dispatch(deleteFromBlackList(url,{playerId:deletePlayerId})).then(
        data  => {
          setDeletePlayerId(false)
          setDeleteModalVisible(false)
        }
    );
    } else {
      setDeleteModalVisible(false)
    }

  }

  const openDeleteModal = (id) => {
    setDeletePlayerId(id)
    setDeleteModalVisible(true)
  }

  const loadAsyncApplications = (team_id) => {
    dispatch(fetchRoster(team_id)).then(
      roster => {
        if(roster && roster.data && roster.data.applications && roster.data.applications.length){
          setTempRoster(roster.data)
          const temp_applications = []
          roster.data.applications.map((item, index) => {
            item.key = index;
            item.name = item.application_name
            temp_applications.push(item)
          })
  
          setApplicationList([...temp_applications]) 
        } else {
          setTempRoster([])
        }
      });
  }

  const renderContent = () => {
      if(!loading){
        if(black_list && black_list.length > 0){
            return (
          <div className="blackListWrapperContent">
            {
              black_list.map((item,index) => (
                <div key={index} style={{marginBottom:20,marginTop:20,marginLeft:20,marginRight:20}}>
                <BlackListPlayer data={item} collapsedPlayersids={collapsedPlayersids} onPress={(id)=>collapsePlayer(id)} deletePlayer={(id)=>openDeleteModal(id)}/>
                </div>
              ))
            }
          </div>
        )
      } else {
        return (
          <div className="emptyWrapper">
            <span className="emptyText">Похоже игроков нет...</span>
          </div>
        )
      }
    } else {

      return (
        <div className="loadingOverlay">
          <div className="loadingWrapper">
            <Loader
              type="Rings"
              color="#04a9f5"
              height={40}
              width={40}
              timeout={0}/>
            <span style={{marginLeft:10}}>Загрузка черного списка</span>
          </div>
        </div>
      )
    }
  }

  const makeSearch = (family,name, type, teamName) => {
    if(type == 'player'){
      let url = API_URLS.searchPlayersByParams;
      dispatch(searchPlayers(url,{family,name,father_name:''})).then(
        list => {
  
        });
    } else {
      let url = API_URLS.getAdminTeams;
      dispatch(fetchAdminTeams(url, teamName)).then(
        list => {
  
        });
    }
  }





  return (
    <div className="layoutWrapper">
      <div className="matchCenterWrapper">
        <div className="matchCenterHeader">
          <div className="simpleRow" style={{justifyContent:'space-between',paddingRight:50}}>
          <span className="blackListName">Черный список</span>
          <SearchBar onChange={(input)=>searchInput(input)}/>
          <div onClick={()=>searchPlayersWidgetToggle('open')} className="matchChangeResultsButton">
              <span className="matchChangeResultsButtonText">Добавить</span>
            </div>
          </div>
        </div>
        {renderContent()}
      </div>
      <SearchPlayersSideBar 
        closeWidget={()=>searchPlayersWidgetToggle('close')} 
        searchPlayers={(family,name, type, teamName)=>makeSearch(family,name,type, teamName)}
        data={searchPlayersData}
        teamData={searchTeamsData}
        errorAdd={error}
        fetchRoster={loadAsyncApplications}
        roster={tempRoster}
        clearRoster={()=>setTempRoster(false)}
        addPlayerToBlackList={addPlayerToBlackList}
        applicationList={applicationList}
        loading={searchPlayersLoading}/>
              <CustomModal
          styles={{width:330, height:'auto',paddingLeft:50, paddingRight:50}}
          visible={deleteModalVisible}
          closeModal={()=>setDeleteModalVisible(state => !state)}>
          <div>
            <div className="customModalHeader">
              Удаление из черного списка
            </div>
            <p style={{marginTop:10}} className="declineModalText">Вы действительно хотите удалить игрока из черного списка?</p>
            <div className="manageApplicationButtonsModalWrapper">
              <div onClick={()=>deletePlayers()} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Удалить              
                </span>
              </div>
              <div onClick={()=>{
                setDeleteModalVisible(false)
              }} style={{backgroundColor:'rgb(253,54,71,1)'}} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Отменить              
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
    </div>
  );
}