import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultTeamLogo from "../images/defaultTeamLogo.png";
import { BASE_URL } from "../constants/config";
import Loader from "react-loader-spinner";
import moment from 'moment'
import useDynamicRefs from 'use-dynamic-refs';
import BottomModal from '../components/BottomModal';
import { FiAlertTriangle,FiAlertCircle, FiCheckCircle, FiXCircle, FiMessageSquare  } from "react-icons/fi";
import { IoIosArrowDown  } from "react-icons/io";
import CustomModal from "../components/CustomModal";
import _ from "lodash";
import Collapse from "@kunukn/react-collapse";
import {fetchTeamApplicationQuery, fetchPlayerApplications, acceptApllication, declineApllication} from '../actions/applicationsActions'
import Popup from 'reactjs-popup';


export default function ManageApplication(props) {
  const [playersComment, setPlayersComment] = useState('');
  const [playersCommentPid, setPlayersCommentPid] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [declineModalVisible, setDeclineModalVisible] = useState(false);
  const [declineComment, setDeclineComment] = useState('');
  const [acceptModalVisible, setAcceptModalVisible] = useState(false);

  const [teamModal, setTeamModal] = useState({
    visible: false,
    player: false,
    teams: []
  })
  const dispatch = useDispatch();
  const [activePlayerSections, setActivePlayerSections] = useState([]);
  const fullApplication = useSelector(state => state.applications.teamFullApplication.data);
  const loading = useSelector(state => state.applications.loading);
  const playerApplications = useSelector(state => state.applications.playerApplications.data);
  const playerLoading = useSelector(state => state.applications.playerLoading);
  const acceptLoading = useSelector(state => state.applications.acceptLoading);
  const declineLoading = useSelector(state => state.applications.declineLoading);
  const [application, setApplication] = useState(false);
  const [players, setPlayers] = useState([]);
  const [copies, setCopies] = useState([]);



  useEffect(() => {
    _loadAsyncApplication(props.match.params.id)
  }, []);

  const _loadAsyncApplication = (application_id) => {
    dispatch(fetchTeamApplicationQuery(application_id)).then(application => {
      if(application && application.data){
        if(application.data.application){
          setApplication({...application.data.application})
        }
        if(application.data.players && application.data.players.length){
          setPlayers([...application.data.players])
        }
        if(application.data.copies && application.data.copies.length){
          setCopies([...application.data.copies])
        }
      }
    })
  }

  const _declineAsyncApplication = (application_id, application_data) => {
    dispatch(declineApllication(application_id, application_data)).then(applications => {
      _loadAsyncApplication(props.match.params.id)

    })
  }

  const _acceptAsyncApplication = (application_id, application_data) => {
    dispatch(acceptApllication(application_id, application_data)).then(applications => {
      _loadAsyncApplication(props.match.params.id)

    })
  }



  const _loadAsyncPlayerApplications = (player_id) => {
    dispatch(fetchPlayerApplications(player_id))
  }


  const rendeTeamImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultLogo.png')){
      return (
        <img src={defaultTeamLogo} className="manageApplicationHeaderTeamInfoImage"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="manageApplicationHeaderTeamInfoImage"/>  
      )
    }
  }

  const rendePlayerImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultLogo.png')){
      return (
        <img src={defaultTeamLogo} className="manageApplicationPlayerImage"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="manageApplicationPlayerImage"/>  
      )
    }
  }


  const getStatus = (status_type) => {
    switch(parseInt(status_type)){
      case 1: {
        return 'Подтверждена'
        break;
      }
      case 0: {
        return 'Отклонена'
        break;
      }
      case 3: {
        return 'Частично отклонена'
        break;
      }
      case 2: {
        return 'Ждет подтверждения'
        break;
      }
      default: {
        break;
      }
    }
  }

  const getPlayersStatus = (status_type) => {
    switch(parseInt(status_type)){
      case 0:
      case 3:  
      {
        return 'Ждет подтверждения'
        break;
      }
      case 1:
      case 4: {
        return 'Подтвержден'
        break;
      }
      case 2:
      case 5: {
        return 'Отклонен'
        break;
      }
      default: {
        break;
      }
    }
  } 


  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const changePlayerStatus = (player_id, old_status, action ) => {
    let new_status = false;
    if(action == 'accept'){
      if([0,1,2].findIndex(st => st == old_status) !== -1){
        new_status = 1;
      } else if([3,4,5].findIndex(st => st == old_status) !== -1) {
        new_status = 4
      } else {
        new_status = 7
      }
    } else {
      if([0,1,2].findIndex(st => st == old_status) !== -1){
        new_status = 2;
      } else if([3,4,5].findIndex(st => st == old_status) !== -1) {
        new_status = 5
      } else {
        new_status = 8;
      }
    }
    if(new_status){
      const temp_players = players;
      const temp_index = temp_players.findIndex(player => player.id == player_id)
      temp_players[temp_index].status = new_status
      setPlayers([...temp_players])
    }

  }

  const declineApplication = () => {
    setApplication(state => ({...state, admin_comment: declineComment}))
    setDeclineModalVisible(state=>!state)
    let application_data = {
      admin_comment: declineComment,
      team_id: application.team_id,
      team_name: application.team_name,
      created_id: application.created_id,
    }
    _declineAsyncApplication(application.cap_application_id,application_data)
  }

  const acceptApplication = () => {
    setAcceptModalVisible(state=>!state)
    let full_teams_ids = [];
    if(copies && copies.length) {
      copies.map(copy => full_teams_ids.push(copy.team_id))
      full_teams_ids.unshift(application.team_id)
    }
    let application_data = {
      admin_comment: '',
      application_id: application.application_id,
      team_id: full_teams_ids && full_teams_ids.length ?full_teams_ids :application.team_id,
      team_name: application.team_name,
      created_id: application.created_id,
      newPlayers: players.filter(pl => (pl.status === 0 || pl.status == 1 || pl.status == 2) && (!pl.player_id || pl.player_id == null) ),
      oldPlayerAdd: players.filter(pl => (pl.status === 0 || pl.status == 1 || pl.status == 2) && pl.player_id && pl.player_id != null ),
      oldPlayersRemove: players.filter(pl => pl.status == 3 || pl.status == 4 || pl.status == 5 ),
      changePlayers: players.filter(pl => pl.status == 6 || pl.status == 7 || pl.status == 8 )
    };
    _acceptAsyncApplication(application.cap_application_id,application_data)

  }

  const approveAllPlayers = () => {
    const temp_players = players;
    if(temp_players.findIndex(pl => pl.status && pl.status != 0 && pl.status !=3 && pl.status !=6 && pl.status !=null) != -1){
      temp_players.map(pl => {
        if([0,1,2].findIndex(st => st == pl.status) !== -1){
          pl.status = 0;
        } else if([3,4,5].findIndex(st => st == pl.status) !== -1) {
          pl.status = 3;
        } else {
          pl.status = 6;
        }
      })
    } else {
      temp_players.map(pl => {
        if([0,1,2].findIndex(st => st == pl.status) !== -1){
          pl.status = 1;
        } else if([3,4,5].findIndex(st => st == pl.status) !== -1) {
          pl.status = 4
        } else {
          pl.status = 7
        }
      })
    }
    setPlayers([...temp_players])

  }

  const addComment = () => {
    const temp_players = players;
    const temp_index = temp_players.findIndex(player => player.id == playersCommentPid)
    temp_players[temp_index].admin_comment = playersComment
    setPlayers([...temp_players])
    setPlayersComment('')
    setPlayersCommentPid(false)
    setModalVisible(false)
  }

  const togglePlayers = (id) => {
    const temp_array = activePlayerSections;
    const temp_index = temp_array.findIndex(player_id => player_id == id)
    if(temp_index != -1){
      temp_array.splice(temp_index, 1);
    }
    setActivePlayerSections(state =>  temp_index !== -1
    ?[...temp_array]
    :[...state,id])
  }

  const renderTeams = (teams) => {
      return (
        <div className="manageApplicationPlayerCollapseTeamName">
        {teams}
        </div>
      )
  }

  const openTeamsDetails = (player) => {
    if(player.player_id){
      _loadAsyncPlayerApplications(player.player_id)
      setTeamModal({
        visble: true,
        player: player,
        //teams: teams
      })
    }
  }

  const renderPlayerTeams = (player) => {
    if(player.teams_count != false && player.teams_count != null){
      if(parseInt(player.teams_count)>3){
        return (
          <div className="manageApplicationHeaderTextColumnWithHint">
            <div className="manageApplicationPlayerCollapseTeamsHeader">
              Участие в заявках:
            </div>
              {renderTeams(player.first_three_teams)}
              <div className="manageApplicationPlayerCollapseTeamName">
              И еще {parseInt(player.teams_count) - 3}
              </div>
              <div onClick={()=>openTeamsDetails(player)} className="manageApplicationPlayerCollapseTeamsHint">
              Подробнее
            </div>
          </div>
        )
      } else {
        return (
          <div className="manageApplicationHeaderTextColumnWithHint">
          <div className="manageApplicationPlayerCollapseTeamsHeader">
          Участие в заявках:
          </div>
            {renderTeams(player.first_three_teams)}
            <div onClick={()=>openTeamsDetails(player)}  className="manageApplicationPlayerCollapseTeamsHint">
            Подробнее
          </div>
        </div>
        )
      }
    } else {
      return (
        <div className="manageApplicationHeaderTextColumnWithHint">
        <div className="manageApplicationPlayerCollapseTeamsHeader">
        Участие в заявках:
        </div>
          <div className="manageApplicationPlayerCollapseTeamName">
          Активных заявок нет
        </div>
      </div>
      )
    }
  }

  const collapsePlayers = () => {
    if(activePlayerSections.length){
      setActivePlayerSections([])
    } else {
      const temp_array = []
      players.map(item => temp_array.push(item.id))
      setActivePlayerSections(state=> [...temp_array])
    }

  }

  const renderWarning = (player) => {
    if(player.black_list_id && player.black_list_id !== null){
      return (
        <div className="manageApplicationPlayerWraning">
        <div className="matchResultsErrorContent">
        <div className="matchResultsWarningRow" style={{justifyContent:'center',paddingRight:20, paddingRight:20}}>
          <FiAlertCircle size="1.5em" color="white"/>
          <span style={{marginLeft:10}} className="bottomModalText">Игрок находится в черном списке!</span>
        </div>
        </div>
        </div> 
      )
    } else {
      let warning_text = false;
      if(player.status == 1 || player.status === 0 || player.status === 2 || player.status == 6 || player.status === 7 || player.status === 8){
        if(player.player_number && player.player_number != null &&
          players.length &&
          players.findIndex(pl => pl.player_number && pl.player_number != null  && (pl.player_number == player.player_number && pl.id != player.id) ) != -1)
          warning_text = 'Два игрока с одинаковым номером'
        if(player.player_number && player.player_number != null &&
          fullApplication.current_application.length &&
          fullApplication.current_application.findIndex(pl => pl.number && pl.number != null  && pl.number == player.player_number) != -1)
          warning_text = 'Данный номер занят другим игроком в заявке команды'
        if(player.player_id && fullApplication.current_application &&
          fullApplication.current_application.length &&
          fullApplication.current_application.findIndex(pl => pl.id == player.player_id) != -1) warning_text = 'Игрок уже есть в заявке команды'
          if(warning_text){
            return (
              <div className="manageApplicationPlayerWraning">
              <div className="matchResultsWarningContent">
              <div className="matchResultsWarningRow" style={{justifyContent:'center',paddingRight:20, paddingRight:20}}>
                <FiAlertTriangle size="1.5em" color="#8A6D3B"/>
                <span style={{marginLeft:10}} className="bottomModalText">{warning_text}</span>
              </div>
              </div>
              </div>
            )
          } else {
            return null
          }
      } else {
        return null
      }
    }

  }

  const renderModalApplications = () => {
    if(!playerLoading && playerApplications){
      console.log('playerApplications',playerApplications)
      return playerApplications.map((item, index) => {
        return (
          <div className="playerTeamsModalRow">
            <div className="playerTeamsModalTeamName">
              {item.team_name}
            </div>
            <div className="playerTeamsModalApplicationName">
              {item.application_name}
            </div>
            <div className="playerTeamsModalLeagueName">
              {item.league_name}
            </div>
          </div>
        )
      })
    } else {
      return null;
    }

  }

  const renderPlayers = () => {
    return players.map((item,index) => {
      return (
        //              <div className={item.status > 2 ?item.status > 5 ?`manageApplicationPlayerStatusChange` :`manageApplicationPlayerStatusDecline` :`manageApplicationPlayerStatusAccept`}>

        <div className={item.black_list_id && item.black_list_id !== null ?`manageApplicationPlayerWrapperDisabled` :`manageApplicationPlayerWrapper`} key={index}>
          <div className="manageApplicationPlayerMainRow">
            {renderWarning(item)}
            <div className="manageApplicationHeaderTeamInfoWrapper">
              <div className={item.status > 2 ?item.status > 5 ?`manageApplicationPlayerStatusChange` :`manageApplicationPlayerStatusDecline` :`manageApplicationPlayerStatusAccept`}>
                {item.status > 2 ?item.status > 5 ?'Изменить' :'Отзаявить' :'Заявить'}
              </div>
            <div className="manageApplicationPlayerImageWrapper">
              {rendePlayerImage(item.image)}   
            </div>
            </div>
              <div className="manageApplicationPlayerSubRow">
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationStatus">
                    {item.family} {item.name} {item.father_name}
                  </div>
                  <div className="manageApplicationHeaderHint">
                    ФИО
                  </div>
                </div>
                <div style={{marginLeft:100,minWidth:0}} className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationStatus">
                    {item.player_number}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderHint">
                    Игровой номер
                  </div>
                </div>
              </div>
              <div onClick={()=>togglePlayers(item.id)}>
              <IoIosArrowDown size="2em" color="black"/>
              </div>
              <div className="manageApplicationPlayerButtonsColumn">
              <div onClick={()=>changePlayerStatus(item.id, item.status, 'accept')} className={item.status && (item.status === 1 || item.status == 4 || item.status == 7) ?`manageApplicationPlayerAcceptButtonsEnabled` :`manageApplicationPlayerAcceptButtonsDisabled`}>
                <FiCheckCircle size="2em" />
              </div>
              <div onClick={()=>changePlayerStatus(item.id, item.status, 'decline')} style={{marginLeft:20}} className={item.status &&  (item.status == 2 || item.status == 5 || item.status == 8) ?`manageApplicationPlayerDeclineButtonsEnabled` :`manageApplicationPlayerDeclineButtonsDisabled`}>
                <FiXCircle size="2em"/>
              </div>
              <div onClick={()=>{
                setPlayersCommentPid(item.id)
                setPlayersComment(item.admin_comment !==false && item.admin_comment !==null && item.admin_comment.length ?item.admin_comment :'')
                setModalVisible(true)
              }} style={{marginLeft:20}}
              className={item.admin_comment !==false && item.admin_comment !==null && item.admin_comment.length ?`manageApplicationPlayerCommentButtonsEnabled` :`manageApplicationPlayerCommentButtonsDisabled`}>
                <FiMessageSquare size="2em"/>
              </div>
              </div>
          </div>
          <Collapse isOpen={activePlayerSections.findIndex(player_id => player_id == item.id) !== -1}>
          <div className="manageApplicationPlayerCollapseRow">
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationCollapseValue">
                    {item.height}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderCollapseHint">
                    Рост
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationCollapseValue">
                    {item.weight}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderCollapseHint">
                    Вес
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationCollapseValue">
                    {item.birthday ?moment(item.birthday).format("DD/MM/YYYY") :''}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderCollapseHint">
                    Дата рождения
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationCollapseValue">
                    {item.nationality}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderCollapseHint">
                    Национальность
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationCollapseValue">
                    {item.work_foot}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderCollapseHint">
                    Рабочая нога
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationCollapseValue">
                    {item.short_position}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderCollapseHint">
                    Позиция
                  </div>
                </div>
              </div>
              <div className="manageApplicationPlayerCollapseRow">
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationCollapseValue">
                    {item.admin_comment}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderCollapseHint">
                    Комментарий администратора
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationCollapseValue">
                    {getPlayersStatus(item.status)}
                  </div>
                  <div style={{minWidth:0}} className="manageApplicationHeaderCollapseHint">
                    Статус
                  </div>
                </div>
                {renderPlayerTeams(item)}

              </div>
          </Collapse>
        </div>

      )
    })
  }

  const num_word = (value, words) =>{  
    value = Math.abs(value) % 100; 
    var num = value % 10;
    if(value > 10 && value < 20) return words[2]; 
    if(num > 1 && num < 5) return words[1];
    if(num == 1) return words[0]; 
    return words[2];
  }

  const rendeCopyTeamImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultLogo.png')){
      return (
        <img src={defaultTeamLogo} className="manageApplicationPopupRelatedTeamImage"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="manageApplicationPopupRelatedTeamImage"/>  
      )
    }
  }

  const renderCopies = () => {
    if(copies && copies.length){
      return (
        <Popup
          trigger={
            <span className="manageApplicationHeaderButton">И еще {copies.length} {num_word(copies.length, ['связанная', 'связанные', 'связанных'])} {num_word(copies.length, ['команда', 'команды', 'команд'])}</span>
          }
          position={'right top'}
          on={['hover', 'focus']}>
            <div className="manageApplicationPopupRelatedTeamsWrapper">
              {copies.map((copy,index) => (
                <div key={'popup_team_' + index} className="manageApplicationPopupRelatedTeam">
                  {rendeCopyTeamImage(copy.image)}   
                  <div className="manageApplicationPopupRelatedTeamName">
                    {copy.name}
                  </div>
                </div>
              ))}
            </div>
        </Popup>
      )
    } else {
      return null;
    }
  }

  const renderContent = () => {
      return (
          <div style={{width:'100%'}}>
            <div className="manageApplicationHeaderWrapper">
              <div className="manageApplicationHeaderNameWrapper">
                Информация
              </div>
              <div className="manageApplicationHeaderRow">
                <div className="manageApplicationHeaderTeamInfoWrapper">
                  <div className="manageApplicationHeaderTeamInfoImageWrapper">
                   {rendeTeamImage(application.team_image)}   
                  </div>
                  <div className="manageApplicationHeaderTeamInfoName">
                  {application.team_name}
                  </div>
                  {renderCopies()}
                  <div className="manageApplicationHeaderTeamCapData">
                    Капитан: {application.cap_family} {application.cap_name}
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationName">
                    {application.application_name}
                  </div>
                  <div className="manageApplicationHeaderHint">
                    Название заявки
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderApplicationStatus">
                    {getStatus(application.status)}
                  </div>
                  <div className="manageApplicationHeaderHint">
                      Статус заявки
                    </div>
                </div>

              </div>
              <div style={{marginTop:10}} className="manageApplicationHeaderNameWrapper">
                Комментарии
              </div>
              <div style={{alignItems:'flex-end'}} className="manageApplicationHeaderRow">
              <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderCommentBlock">
                    {application.cap_comment}
                  </div>
                  <div className="manageApplicationHeaderHint">
                     Комментарий капитана
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderCommentBlock">
                    {application.admin_comment}
                  </div>
                  <div className="manageApplicationHeaderHint">
                    Комментарий администратора
                  </div>
                </div>
              </div>
              <div style={{marginTop:10}} className="manageApplicationHeaderNameWrapper">
                Количество
              </div>
              <div className="manageApplicationHeaderRow">
              <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderCommentBlock">
                    {players.length ?players.length :0}
                  </div>
                  <div style={{minWidth:100}} className="manageApplicationHeaderHint">
                     Всего заявлено
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderCommentBlock">
                    {players.length 
                    ?players.filter(pl => pl.status == 1 || pl.status == 4).length 
                    :0}
                  </div>
                  <div style={{minWidth:100}} className="manageApplicationHeaderHint">
                    Подтверждено
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderCommentBlock">
                  {players.length 
                    ?players.filter(pl => pl.status == 2 || pl.status == 5).length 
                    :0}
                  </div>
                  <div style={{minWidth:100}} className="manageApplicationHeaderHint">
                     Отклонено
                  </div>
                </div>
                <div className="manageApplicationHeaderTextColumnWithHint">
                  <div className="manageApplicationHeaderCommentBlock">
                  {players.length 
                    ?players.filter(pl => pl.status === 0 || pl.status == 3).length 
                    :0}
                  </div>
                  <div style={{minWidth:100}} className="manageApplicationHeaderHint">
                     Ждут потверждения
                  </div>
                </div>
              </div>
              <div className="simpleRow borderBottom">
              <div style={{marginTop:10}} className="manageApplicationHeaderNameWrapper">
                Игроки
              </div>
              <div className="simpleRow" style={{marginLeft:100,width:500,justifyContent:'space-between'}}>
              <span onClick={collapsePlayers} className="manageApplicationHeaderButton">{activePlayerSections.length ?'Свернуть' :'Развернуть'} всех игроков</span>
              <span onClick={approveAllPlayers} className="manageApplicationHeaderButton">
                {players.findIndex(pl => pl.status && pl.status != 0 && pl.status !=3 && pl.status !=null && pl.status !=6) != -1
                  ?'Сбросить статус всех игроков'
                  :'Подтвердить всех игроков'
                }
                
                </span>

              </div>  

              </div>
              {renderPlayers()}
            </div>
          </div>
      )

  }

  const goToRosters = () => {
    const win = window.open("/team_rosters/" + application.team_id + "/" + application.application_id, "_blank");
    win.focus();
  }


  if(!loading && application && !_.isEmpty(application)){
  return (
    <div className="layoutWrapper">
        <div className="matchCenterWrapper">
          <div className="matchResultsHeader">
            <span className="matchResultsName">Заявка #{application.cap_application_id}. Управление.</span>
            <div className="manageApplicationButtonsWrapper">
            <div onClick={()=>goToRosters()} className="matchChangeResultsButton">
              <span className="matchChangeResultsButtonText">
                Текущая заявка ({fullApplication && fullApplication.current_application && fullApplication.current_application.length ?fullApplication.current_application.length : 0})              
                </span>
            </div>
            {/* <div>
              <span className="matchChangeResultsAmountApplication">
                Текущая заявка  - {fullApplication && fullApplication.current_application && fullApplication.current_application.length ?fullApplication.current_application.length : 0} игроков              
                </span>
            </div> */}
            <div onClick={()=>setAcceptModalVisible(true)} className="matchChangeResultsButton">
              <span className="matchChangeResultsButtonText">
                {
                  //loadingFetchApplication 
                  false
                    ?
                    <Loader
                    type="TailSpin"
                    color="white"
                    height={13}
                    width={13}
                    timeout={0}/>
                    : 'Подтвердить'
                }                
                </span>
            </div>
            </div>
          </div>
          {renderContent()}
        </div>
        <CustomModal
          styles={{width:330, height:'auto',paddingLeft:50, paddingRight:50}}
          visible={modalVisible}
          closeModal={toggleModal}>
          <div>
            <div className="customModalHeader">
              Оставить комментарий
            </div>
            <textarea
            type="text" 
            className="manageApplicationPlayerCommentInput"
            value={playersComment} 
            placeholder="Комментарий"
            onChange={e => {setPlayersComment(e.target.value)}}/>
            <div className="manageApplicationButtonsModalWrapper">
              <div onClick={()=>addComment()} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Добавить              
                </span>
              </div>
              <div onClick={()=>{
                setPlayersComment('')
                setModalVisible(false)
              }} style={{backgroundColor:'rgb(253,54,71,1)'}} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Отменить              
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          visible={teamModal.visble}
          closeModal={()=>setTeamModal({visible:false, player: false})}>
          <div>
            <div className="customModalHeader">
              Команды игрока {teamModal.player.family} {teamModal.player.name}
            </div>
            <div className="playerTeamsModalRowHeader">
              <div className="playerTeamsModalTeamNameHeader">
                Команда
              </div>
              <div className="playerTeamsModalApplicationNameHeader">
                Заявка
              </div>
              <div className="playerTeamsModalLeagueNameHeader">
                Лига
              </div>
            </div>
            <div className="playerTeamsModalColumn">
              {renderModalApplications()}
            </div>

          </div>
        </CustomModal>
        <CustomModal
          styles={{width:330, height:'auto',paddingLeft:50, paddingRight:50}}
          visible={declineModalVisible}
          closeModal={()=>setDeclineModalVisible(state => !state)}>
          <div>
            <div className="customModalHeader">
              Отклонение заяки
            </div>
            <p style={{marginTop:10}} className="declineModalText">Изменения не будут добавлены в заявку. Вы действительно хотите отклонить заявку?</p>
            <p style={{marginTop:10}} className="declineModalText">При необходимости добавьте комментарий для капитана</p>
            <textarea
            type="text" 
            className="manageApplicationPlayerCommentInput"
            value={declineComment} 
            placeholder="Причина отклонения заявки"
            onChange={e => {setDeclineComment(e.target.value)}}/>
            <div className="manageApplicationButtonsModalWrapper">
              <div onClick={()=>declineApplication()} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Продолжить              
                </span>
              </div>
              <div onClick={()=>{
                setDeclineComment('')
                setDeclineModalVisible(false)
              }} style={{backgroundColor:'rgb(253,54,71,1)'}} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Отменить              
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          styles={{width:330, height:'auto',paddingLeft:50, paddingRight:50}}
          visible={acceptModalVisible}
          closeModal={()=>setAcceptModalVisible(state => !state)}>
          <div>
            <div className="customModalHeader">
              Подтверждение заявки
            </div>
            <p style={{marginTop:10}} className="declineModalText">Изменения будут добавлены в заявку 
              {players.length 
                    ?' ( ' + (players.length - players.filter(pl => pl.status === 0 || pl.status == 3).length) + ' игроков из ' + players.length + ' )'
                    :''}
              . Вы действительно хотите принять заявку?</p>
            <div className="manageApplicationButtonsModalWrapper">
              <div onClick={()=>acceptApplication()} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Продолжить              
                </span>
              </div>
              <div onClick={()=>{
                setAcceptModalVisible(false)
              }} style={{backgroundColor:'rgb(253,54,71,1)'}} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Отменить              
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
    </div>
  );    
} else {
              return (
                <div className="loadingOverlay">
                  <div className="loadingWrapper">
                    <Loader
                      type="Rings"
                      color="#04a9f5"
                      height={40}
                      width={40}
                      timeout={0}/>
                    <span style={{marginLeft:10}}>Загрузка заявки</span>
                  </div>
                </div>
              )
            }
}