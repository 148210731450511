import {
  GET_ALL_STADIUMS_BEGIN, GET_ALL_STADIUMS_SUCCESS, GET_ALL_STADIUMS_FAILURE} from '../constants/action-types';

const initialState = {
  stadiumsData: {},
  loading: false,
  error: null
};

export default function stadiumReducer(state = initialState, action) {
  switch(action.type) {
    case GET_ALL_STADIUMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_ALL_STADIUMS_SUCCESS:
      return {
        ...state,
        loading: false,
        stadiumsData: action.payload.stadiumsData
      };

    case GET_ALL_STADIUMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        stadiumsData: {}
      };

    default:
      return state;
  }
}