import React from "react";
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import logo from "../images/logo.png";

function SideBar(props) {

  return (
    <ProSidebar collapsed={props.collapsed}>
      <SidebarHeader>
        <div className="sideBarHeader">
          <img src={logo} className="sideBarHeaderLogo" alt="Лого" />
          <div className="sideBarHeaderTitle"><p>inGame</p></div>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square">
          <MenuItem><div onClick={()=>props.goTo('/admin')} className="sidebar-row">Управление матчами </div></MenuItem>
          <MenuItem><div onClick={()=>props.goTo('/applications_managment')} className="sidebar-row">Управление заявками </div></MenuItem>
          <MenuItem><div onClick={()=>props.goTo('/black_list')} className="sidebar-row">Черный список </div></MenuItem>
        </Menu>
    </SidebarContent>
  </ProSidebar>
  );
}

export default SideBar;