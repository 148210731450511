import React from "react";
import Calendar from 'react-calendar'

function CustomCalendar(props) {

  return (
    <div ref={props.ref}>
      <Calendar 
        value={props.currentDate}
        onChange={props.changeDate}/>
    </div> 
  );
}

export default CustomCalendar;