import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {fetchMatch, fetchMatchActiveApplication, setMatchAllResults, setMatchExtraData,setMatchBestPlayer, unsetMatchBestPlayer} from '../actions/matches'
import {fetchAllStadiums} from '../actions/stadiumsActions';
import {fetchAllReferees} from '../actions/judjesActions';
import {API_URLS} from '../constants/config'
import defaultTeamLogo from "../images/defaultTeamLogo.png";
import { BASE_URL } from "../constants/config";
import Loader from "react-loader-spinner";
import moment from 'moment'
import useDynamicRefs from 'use-dynamic-refs';
import BottomModal from '../components/BottomModal';
import { FiAlertTriangle } from "react-icons/fi";
import _ from "lodash";
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import useComponentVisible from '../utilities/useComponentVisible';
import CustomCalendar from '../components/CustomCalendar';
import CustomModal from "../components/CustomModal";
import PlayerMenu from '../components/PlayerMenu';



export default function ManageMatchResult(props) {
  const [matchData, setMatchData] = useState(false);
  const [referee, setReferee] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [stadium, setStadium] = useState('');
  const modalRef = useRef();
  const [matchApplication, setMatchApplication] = useState(false);
  const [stadiumsData, setStadiumsData] = useState([]);
  const [refereesData, setReferees] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalResheduled, setModalResheduled] = useState(false);
  const [modalTechLose, setModalTechLose] = useState(false);
  const [matchComment,setMatchComment] = useState('');
  const [timelineDis,setTimelineDis] = useState(false);
  const [matchScore, setMatchScore] = useState({home: false, guest: false});
  const [matchTechLoseScore, setMatchTechLoseScore] = useState({home: '0', guest: '0'});
  //const [loading, setLoading] = useState(true);
  const [modalText, setmodalText] = useState('');
  const [modalType, setmodalType] = useState('success');
  const [getRef, setRef] =  useDynamicRefs();
  const fetchMatchData = useSelector(state => state.matches.matchData.data)
  const fetchMatchApplication = useSelector(state => state.matches.matchActiveApplication.data);
  const loading = useSelector(state => state.matches.loading);
  const loadingFetchApplication = useSelector(state => state.matches.loadingApplication);
  const dispatch = useDispatch();
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);
  const [currentDate, setcurrentDate] = useState(moment(new Date()));


  useEffect(() => {
    if(!matchData){
      loadAsyncMatch()
    }
  }, [matchData]);

  const loadAsyncMatch = () => {//загрузка матча
    let url = API_URLS.getMatch
    url = url + props.match.params.id;
    dispatch(fetchMatch(url)).then(
      match => {
        setMatchData(match.data)
        setTimelineDis(!match.data.status ||  (match.data.status && match.data.timeline_disabled) ?false :true)
        setMatchComment(match.data.default_comment && match.data.default_comment.length ?match.data.default_comment :'')
        setMatchScore({home:match.data.home_team_scores === 0 ?'0' :match.data.home_team_scores, guest:match.data.guest_team_scores === 0 ?'0' :match.data.guest_team_scores })
        loadAsyncApplication([match.data.home_team_id, match.data.guest_team_id], props.match.params.id)
        setReferee(match.data.judje_id)
        setStadium(match.data.stadium_id)
        setcurrentDate(moment(match.data.date))
        if(match.data.time){
          let time = match.data.time.substring(match.data.time.length - 3 ,0)
          setHours(time.substring(0,2))
          setMinutes(time.slice(-2))
        }
      }
  );

  }

  const setMatchDataAsync = (application, events, newMatchData) => {
    
    let url = API_URLS.setMatchData
    /*props.actions.setMatchAllResults(url, matchData.id, application, events, score).then(
        match => {
          setmodalType('success')
          setmodalText('Результаты матча успешно изменены.')
          toggleModal();
        }
    );*/
    dispatch(setMatchAllResults(url, matchData.id, application, events, newMatchData)).then(
      match => {
        setmodalType('success')
        setmodalText('Результаты матча успешно изменены.')
        toggleModal();
      }
  );

  }

  const setMatchBestPlayerEvent = (data) => {
    let url = API_URLS.setMatchBestPlayer
    const match_id = props.match.params.id
    dispatch(setMatchBestPlayer(url, data, match_id)).then(
      players => {
        if(players.data){
          const matchApplicationTemp = matchApplication
          matchApplicationTemp.map( item => item.mvp = null)
          players.data.map((item,index) => {
            const id =  matchApplicationTemp.findIndex(appl => appl.id == item.id)
            matchApplicationTemp[id].mvp = true;
          })
          setMatchApplication([...matchApplicationTemp])
        }

      }
  );
  };

  const unsetMatchBestPlayerEvent = (data) => {
    let url = API_URLS.unsetMatchBestPlayer
    const match_id = props.match.params.id
    dispatch(unsetMatchBestPlayer(url, data, match_id)).then(
      players => {
        if(players.data){
          const matchApplicationTemp = matchApplication
          matchApplicationTemp.map( item => item.mvp = null)
          players.data.map((item,index) => {
            const id =  matchApplicationTemp.findIndex(appl => appl.id == item.id)
            matchApplicationTemp[id].mvp = true;
          })
          setMatchApplication([...matchApplicationTemp])
        }
      }
    );
  };

  const setMatchExtraDataAsync = (resheduled,tech_lose) => {
    
    let url = API_URLS.setMatchExtraData
    let tech_defeat = false;
    let resheduled_temp = false
    
    if(resheduled){
      resheduled_temp = true;
    } else if(tech_lose && matchTechLoseScore.home !== '0' || matchTechLoseScore.guest !== '0'){
      tech_defeat = true;
    }
    dispatch(setMatchExtraData(url, matchData.id, {
      comment: matchComment && matchComment.length ?matchComment :false,
      resheduled: resheduled_temp,
      tech_defeat,
      timeline_disabled:timelineDis,
      tech_defeat_home:matchTechLoseScore.home,
      tech_defeat_guest:matchTechLoseScore.guest})).then(
      match => {
        setMatchData(match.data)
        setTimelineDis(!match.data.status ||  (match.data.status && match.data.timeline_disabled) ?false :true)
        setMatchComment(match.data.default_comment && match.data.default_comment.length ?match.data.default_comment :'')
        setMatchScore({home:match.data.home_team_scores === 0 ?'0' :match.data.home_team_scores, guest:match.data.guest_team_scores === 0 ?'0' :match.data.guest_team_scores })
        setModalVisible(false)
        setModalTechLose(false)
        setModalResheduled(false)
        setmodalType('success')
        setmodalText('Комментарий к матчу успешно добавлен.')
        toggleModal();
      }
  );

  }

  const setResultsAsync = (application, events, newMatchData) => {
    
    let url = API_URLS.setAllResults
    /*props.actions.setMatchAllResults(url, matchData.id, application, events, score).then(
        match => {
          setmodalType('success')
          setmodalText('Результаты матча успешно изменены.')
          toggleModal();
        }
    );*/
    dispatch(setMatchAllResults(url, matchData.id, application, events, newMatchData)).then(
      match => {
        setMatchData(match.data)
        setTimelineDis(!match.data.status ||  (match.data.status && match.data.timeline_disabled) ?false :true)
        setMatchComment(match.data.default_comment && match.data.default_comment.length ?match.data.default_comment :'')
        setMatchScore({home:match.data.home_team_scores === 0 ?'0' :match.data.home_team_scores, guest:match.data.guest_team_scores === 0 ?'0' :match.data.guest_team_scores })
        setmodalType('success')
        setmodalText('Результаты матча успешно изменены.')
        toggleModal();
      }
  );

  }

  const getRandomInt = (half_time) => {
    const min = 11;
    const max = half_time * 2;
    return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
  }


  const loadAsyncApplication = (team_id, match_id) => { // загрузка заявки
    let url = API_URLS.getMatchActiveApplication;
    dispatch(fetchMatchActiveApplication(url, team_id, match_id)).then(
      application => {
        console.log('application',application)
        setMatchApplication(application.data)  
        loadAsyncStadiums();
      }
  );
  };

  const loadAsyncStadiums = () => { // загрузка стадионов
    let url = API_URLS.getAllStadiums;
    dispatch(fetchAllStadiums(url)).then(
      stadiums => {
        const temp_stadiums = []
        stadiums.data.map((item, index) => {
          item.value = item.id;
          item.key = index
          temp_stadiums.push(item)
        })
        setStadiumsData(temp_stadiums)  
        loadAsyncReferees();
      }
  );
  };

  const loadAsyncReferees = () => { // загрузка судей
    let url = API_URLS.getAllReferees;
    dispatch(fetchAllReferees(url)).then(
      referees => {
        const temp_referees = []
        referees.data.map((item, index) => {
          item.value = item.id;
          item.key = index;
          item.name = item.family + ' ' + item.name
          temp_referees.push(item)
        })

        setReferees(temp_referees)  
      }
  );
  };

  const getErrorMessage = (players) => {
    if(players.length == 1){
      setmodalText('Игрок ' + players[0].family + ' ' + players[0].name + ' ( ' + players[0].team_name + ') совершил события, но не завялен на матч.')
    } else {
      setmodalText('Игроки ' + players[0].family + ' ' + players[0].name + ' ( ' + players[0].team_name + ') и еще ' + (players.length - 1).toString() + ' совершили события, но не завялены на матч.')
    }
    setmodalType('error')
  }

  const openCalendar = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  const changeDate = (date) => {
    setIsComponentVisible(false)
    setcurrentDate(date)
  }

  const changeMatchData = () => {
    const errorPlayers = [];
    const teams = {}
    const newApplication = []
    const events = []
    events.push({
      match_id: matchData.id,
      event_id: 9,
      player_id: null,
      team_id: null,
      league_id : matchData.league_id,
      time: '00:00:00',
      time_number: 1
    })
    events.push({
      match_id: matchData.id,
      event_id: 10,
      player_id: null,
      team_id: null,
      league_id : matchData.league_id,
      time: '00:' + matchData.half_time + ':00',
      time_number: 2
    })
    events.push({
      match_id: matchData.id,
      event_id: 11,
      player_id: null,
      team_id: null,
      league_id : matchData.league_id,
      time: '00:' + (matchData.half_time * 2) + ':00',
      time_number: 2
    })
    let tempApplication = _.cloneDeep(matchApplication);
    let matchDataTemp = _.cloneDeep(matchScore);
    teams[matchData.home_team_id] = matchData.home_team_name;
    teams[matchData.guest_team_id] = matchData.guest_team_name;
    matchApplication.map((player) => {
      let index = -1;
      let max_value = 0;
      tempApplication.map((temp_player, id) => {
        if(temp_player.goals + temp_player.assists > max_value){
          max_value = temp_player.goals + temp_player.assists
          index = id
        }
      })
      const minutes = getRandomInt(matchData.half_time);
      const seconds = getRandomInt(matchData.half_time);
      const time = '00:' + minutes + ':' + seconds
      if(index !== -1 ){
        if(tempApplication[index] && tempApplication[index].goals && tempApplication[index].goals > 0){
          let assist_index = tempApplication.findIndex(player => tempApplication[index].id != player.id && player.team_id == tempApplication[index].team_id && player.assists && player.assists > 0)
          events.push({
            match_id: matchData.id,
            event_id: 1,
            player_id: tempApplication[index].id,
            team_id: tempApplication[index].team_id,
            league_id : matchData.league_id,
            time,
            time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
          })
          if(assist_index !== -1){
            events.push({
              match_id: matchData.id,
              event_id: 2,
              player_id: tempApplication[assist_index].id,
              team_id: tempApplication[assist_index].team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
            tempApplication[assist_index].assists = tempApplication[assist_index].assists - 1
          }
        }
        tempApplication[index].goals = tempApplication[index].goals - 1
      }
      if(!player.active && (parseInt(player.goals) || parseInt(player.autogoals) || parseInt(player.assists) || parseInt(player.yellow_cards) || parseInt(player.red_cards))){
        errorPlayers.push({...player, team_name: teams[player.team_id]})
      }
      if(player.active){
        newApplication.push({match_id:matchData.id, team_id: player.team_id, player_id: player.id, from_double: player.isDouble && player.isDouble != null ?1 :null, })
      }
    })
    if(errorPlayers.length > 0){
      getErrorMessage(errorPlayers)
      toggleModal();
      return null;
    } else {
      tempApplication.map((player) => {
        if(player.goals && player.goals > 0){
          for(let i=0; player.goals > i;i++){
            const minutes = getRandomInt(matchData.half_time);
            const seconds = getRandomInt(matchData.half_time);
            const time = '00:' + minutes + ':' + seconds
            events.push({
              match_id: matchData.id,
              event_id: 1,
              player_id: player.id,
              team_id: player.team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
          }
        }
        if(player.autogoals && player.autogoals > 0){
          for(let i=0; player.autogoals > i;i++){
            const minutes = getRandomInt(matchData.half_time);
            const seconds = getRandomInt(matchData.half_time);
            const time = '00:' + minutes + ':' + seconds
            events.push({
              match_id: matchData.id,
              event_id: 8,
              player_id: player.id,
              team_id: player.team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
          }
        }
        if(player.assists && player.assists > 0){
          for(let i=0; player.assists > i;i++){
            const minutes = getRandomInt(matchData.half_time);
            const seconds = getRandomInt(matchData.half_time);
            const time = '00:' + minutes + ':' + seconds
            events.push({
              match_id: matchData.id,
              event_id: 2,
              player_id: player.id,
              team_id: player.team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
          }
        }
        if(player.yellow_cards && player.yellow_cards > 0){
          for(let i=0; player.yellow_cards > i;i++){
            if(i%2 != 0){
              const minutes = getRandomInt(matchData.half_time);
              const seconds = getRandomInt(matchData.half_time);
              const time = '00:' + minutes + ':' + seconds
              events.push({
                match_id: matchData.id,
                event_id: 13,
                player_id: player.id,
                team_id: player.team_id,
                league_id : matchData.league_id,
                time,
                time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
              })
            } else {
              const minutes = getRandomInt(matchData.half_time);
              const seconds = getRandomInt(matchData.half_time);
              const time = '00:' + minutes + ':' + seconds
              events.push({
                match_id: matchData.id,
                event_id: 3,
                player_id: player.id,
                team_id: player.team_id,
                league_id : matchData.league_id,
                time,
                time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
              })
            }
          }
        }
        if(player.red_cards && player.red_cards > 0){
          for(let i=0; player.red_cards > i;i++){
            const minutes = getRandomInt(matchData.half_time);
            const seconds = getRandomInt(matchData.half_time);
            const time = '00:' + minutes + ':' + seconds
            events.push({
              match_id: matchData.id,
              event_id: 4,
              player_id: player.id,
              team_id: player.team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
          }
        }
      })
      matchDataTemp.stadium_id = null;
      matchDataTemp.judje_id = null;
      matchDataTemp.time = null;
      if(!matchDataTemp.guest || (matchDataTemp.guest && matchDataTemp.guest === '')) matchDataTemp.guest = 0
      if(stadium || stadium != '') matchDataTemp.stadium_id = stadium
      if(referee && referee.length) {
        matchDataTemp.judje_id = referee[0]
        const subRefs = _.cloneDeep(referee);
        subRefs.shift();
        matchDataTemp.subRefs = subRefs;
      } else {
        matchDataTemp.judje_id = referee;
      }
      matchDataTemp.date = currentDate.format("YYYY-MM-DD")
      let tempMinutes = minutes;
      let tempHours = hours;
      if(minutes && hours && minutes.length >=1 && hours.length >=1){
        if(tempMinutes.length == 1){
          tempMinutes = tempMinutes + '0'
        }
        if(tempHours.length == 1){
          tempHours = tempHours + '0'
        }
        matchDataTemp.time = tempHours + ':' + tempMinutes + ':00';
      }
      setMatchDataAsync(newApplication, events, matchDataTemp)
      return null;
    }
  }

  const toggleModalComment = () => {
    setModalVisible(!modalVisible);
  };

  const toggleModalTechLose = () => {
    setModalTechLose(!modalTechLose)
  }

  const toggleModalResheduled = () => {
    setModalResheduled(!modalResheduled)
  }

  const changeResults = () => {
    const errorPlayers = [];
    const teams = {}
    const newApplication = []
    const events = []
    events.push({
      match_id: matchData.id,
      event_id: 9,
      player_id: null,
      team_id: null,
      league_id : matchData.league_id,
      time: '00:00:00',
      time_number: 1
    })
    events.push({
      match_id: matchData.id,
      event_id: 10,
      player_id: null,
      team_id: null,
      league_id : matchData.league_id,
      time: '00:' + matchData.half_time + ':00',
      time_number: 2
    })
    events.push({
      match_id: matchData.id,
      event_id: 11,
      player_id: null,
      team_id: null,
      league_id : matchData.league_id,
      time: '00:' + (matchData.half_time * 2) + ':00',
      time_number: 2
    })
    let tempApplication = _.cloneDeep(matchApplication);
    let matchDataTemp = _.cloneDeep(matchScore);
    teams[matchData.home_team_id] = matchData.home_team_name;
    teams[matchData.guest_team_id] = matchData.guest_team_name;
    matchApplication.map((player) => {
      let index = -1;
      let max_value = 0;
      tempApplication.map((temp_player, id) => {
        if(temp_player.goals + temp_player.assists > max_value){
          max_value = temp_player.goals + temp_player.assists
          index = id
        }
      })
      const minutes = getRandomInt(matchData.half_time);
      const seconds = getRandomInt(matchData.half_time);
      const time = '00:' + minutes + ':' + seconds
      if(index !== -1 ){
        if(tempApplication[index] && tempApplication[index].goals && tempApplication[index].goals > 0){
          let assist_index = tempApplication.findIndex(player => tempApplication[index].id != player.id && player.team_id == tempApplication[index].team_id && player.assists && player.assists > 0)
          events.push({
            match_id: matchData.id,
            event_id: 1,
            player_id: tempApplication[index].id,
            team_id: tempApplication[index].team_id,
            league_id : matchData.league_id,
            time,
            time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
          })
          if(assist_index !== -1){
            events.push({
              match_id: matchData.id,
              event_id: 2,
              player_id: tempApplication[assist_index].id,
              team_id: tempApplication[assist_index].team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
            tempApplication[assist_index].assists = tempApplication[assist_index].assists - 1
          }
        }
        tempApplication[index].goals = tempApplication[index].goals - 1
      }
      if(!player.active && (parseInt(player.goals) || parseInt(player.autogoals) || parseInt(player.assists) || parseInt(player.yellow_cards) || parseInt(player.red_cards))){
        errorPlayers.push({...player, team_name: teams[player.team_id]})
      }
      if(player.active){
        newApplication.push({match_id:matchData.id, team_id: player.team_id, player_id: player.id, from_double: player.isDouble && player.isDouble != null ?1 :null, position_id: player.match_position })
      }
    })
    if(errorPlayers.length > 0){
      getErrorMessage(errorPlayers)
      toggleModal();
      return null;
    } else {
      tempApplication.map((player) => {
        if(player.goals && player.goals > 0){
          for(let i=0; player.goals > i;i++){
            const minutes = getRandomInt(matchData.half_time);
            const seconds = getRandomInt(matchData.half_time);
            const time = '00:' + minutes + ':' + seconds
            events.push({
              match_id: matchData.id,
              event_id: 1,
              player_id: player.id,
              team_id: player.team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
          }
        }
        if(player.autogoals && player.autogoals > 0){
          for(let i=0; player.autogoals > i;i++){
            const minutes = getRandomInt(matchData.half_time);
            const seconds = getRandomInt(matchData.half_time);
            const time = '00:' + minutes + ':' + seconds
            events.push({
              match_id: matchData.id,
              event_id: 8,
              player_id: player.id,
              team_id: player.team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
          }
        }
        if(player.assists && player.assists > 0){
          for(let i=0; player.assists > i;i++){
            const minutes = getRandomInt(matchData.half_time);
            const seconds = getRandomInt(matchData.half_time);
            const time = '00:' + minutes + ':' + seconds
            events.push({
              match_id: matchData.id,
              event_id: 2,
              player_id: player.id,
              team_id: player.team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
          }
        }
        if(player.yellow_cards && player.yellow_cards > 0){
          for(let i=0; player.yellow_cards > i;i++){
            if(i%2 != 0){
              const minutes = getRandomInt(matchData.half_time);
              const seconds = getRandomInt(matchData.half_time);
              const time = '00:' + minutes + ':' + seconds
              events.push({
                match_id: matchData.id,
                event_id: 13,
                player_id: player.id,
                team_id: player.team_id,
                league_id : matchData.league_id,
                time,
                time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
              })
            } else {
              const minutes = getRandomInt(matchData.half_time);
              const seconds = getRandomInt(matchData.half_time);
              const time = '00:' + minutes + ':' + seconds
              events.push({
                match_id: matchData.id,
                event_id: 3,
                player_id: player.id,
                team_id: player.team_id,
                league_id : matchData.league_id,
                time,
                time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
              })
            }
          }
        }
        if(player.red_cards && player.red_cards > 0){
          for(let i=0; player.red_cards > i;i++){
            const minutes = getRandomInt(matchData.half_time);
            const seconds = getRandomInt(matchData.half_time);
            const time = '00:' + minutes + ':' + seconds
            events.push({
              match_id: matchData.id,
              event_id: 4,
              player_id: player.id,
              team_id: player.team_id,
              league_id : matchData.league_id,
              time,
              time_number: minutes > (parseInt(matchData.half_time)) ?2 :1 
            })
          }
        }
      })
      matchDataTemp.stadium_id = null;
      matchDataTemp.judje_id = null;
      matchDataTemp.time = null;
      if(!matchDataTemp.guest || (matchDataTemp.guest && matchDataTemp.guest === '')) matchDataTemp.guest = 0
      if(!matchDataTemp.home || (matchDataTemp.home && matchDataTemp.home === '')) matchDataTemp.home = 0
      if(stadium || stadium != '') matchDataTemp.stadium_id = stadium
      if(referee && referee.length) {
        matchDataTemp.judje_id = referee[0]
        const subRefs = _.cloneDeep(referee);
        subRefs.shift();
        matchDataTemp.subRefs = subRefs;
      } else {
        matchDataTemp.judje_id = referee;
      }
      matchDataTemp.date = currentDate.format("YYYY-MM-DD")
      let tempMinutes = minutes;
      let tempHours = hours;
      if(minutes && hours && minutes.length >=1 && hours.length >=1){
        if(tempMinutes.length == 1){
          tempMinutes = tempMinutes + '0'
        }
        if(tempHours.length == 1){
          tempHours = tempHours + '0'
        }
        matchDataTemp.time = tempHours + ':' + tempMinutes + ':00';
      }
      matchDataTemp.timeline_disabled = timelineDis;
      setResultsAsync(newApplication, events, matchDataTemp)
      return null;
    }
  }


  const rendeTeamImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultLogo.png')){
      return (
        <img src={defaultTeamLogo} className="teamImage"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="teamImage"/>  
      )
    }
  }

  const changeActivity = (player_index) => {
    const matchApplicationTemp = matchApplication
    matchApplicationTemp[player_index].active = !matchApplicationTemp[player_index].active
    setMatchApplication([...matchApplicationTemp])
  }

  const chooseAll = (team_id) => {
      const matchApplicationTemp = matchApplication
      let method = true;
      matchApplicationTemp.map(item => {
        if(item.team_id == team_id && item.active){
          method = false
        }
      })
      matchApplicationTemp.map(item => {
        if(item.team_id == team_id){
          if(method){
            item.active = true;
          } else {
            item.active = false;
          }
        }
      })
      setMatchApplication([...matchApplicationTemp])
  }

  const changeEvents = (event, player_index, event_type) => {
    const matchApplicationTemp = matchApplication
    matchApplicationTemp[player_index][event_type] = event.target.value.replace(/[^0-9\.]/g, '');
    if(parseInt(event.target.value.replace(/[^0-9\.]/g, '')) && parseInt(event.target.value.replace(/[^0-9\.]/g, ''))>0){
      matchApplicationTemp[player_index].active = 1;
    }
    setMatchApplication([...matchApplicationTemp])
  }

  const changeDateTime = (event, event_type) => {
    const input_temp = event.target.value.replace(/[^0-9\.]/g, '');
    if(event_type == 'hours'){
      setHours(input_temp);
    }else {
      setMinutes(input_temp);
    }
  }

  const focusInput = (ref_name) => {
    const qwer = getRef(ref_name);
    if(qwer && qwer.current){
      qwer.current.focus();
    }
  }

  const changeKeeperState = (pl) => {
    if(pl.match_position == 4){
      const matchApplicationTemp = matchApplication
      const index = matchApplicationTemp.findIndex(player => player.id == pl.id)
      matchApplicationTemp[index].match_position = null;
      setMatchApplication([...matchApplicationTemp])
    } else {
      const matchApplicationTemp = matchApplication
      matchApplicationTemp.map( item => {
        if(item.team_id == pl.team_id)
        item.match_position = null 
      })
      const index = matchApplicationTemp.findIndex(player => player.id == pl.id)
      matchApplicationTemp[index].match_position = 4;
      setMatchApplication([...matchApplicationTemp])
    }
  }

  const changeMvpState = (pl) => {
    if(pl.mvp == null){
      const match_id = props.match.params.id
      const data = {
        match_id,
        player_id:pl.id,
        team_id:pl.team_id,
      }
      setMatchBestPlayerEvent(data)
    } else {
      const match_id = props.match.params.id
      const data = {
        match_id,
        player_id:pl.id,
        team_id:pl.team_id,
      }
      unsetMatchBestPlayerEvent(data)
    }
  }

  const getAllAmount = (type, team_id, type2) => {
    let count = 0;
    if(type){
      if(matchApplication){
        matchApplication.map(player => {
          if(player.team_id == team_id && player[type] && player[type] !== 0){
            count = count + parseInt(player[type])
          }
          if(type2 && player.team_id != team_id && player[type2] && player[type2] !== 0){
            count = count + parseInt(player[type2])
          }
        })
        return count;
      } else {
        return 0;
      }
    } else {
      if(matchApplication){
        return matchApplication.filter(player => player.team_id == team_id).length
      } else {
        return 0;
      }
    }
  }

  const toggleModal = () => {
    if(modalRef && modalRef.current && modalRef.current.classList){
      if(modalRef.current.classList.contains('matchResultsModalWrapperActive')) {
        modalRef.current.classList.remove('matchResultsModalWrapperActive')
      }
        modalRef.current.classList.add('matchResultsModalWrapperActive')
        setTimeout(()=> {
          if(modalRef && modalRef.current && modalRef.current.classList)
            modalRef.current.classList.remove('matchResultsModalWrapperActive')
        },5000)
    }
  }

  const renderWarning = () => {
    if(matchData && matchData.home_team_id && matchScore.home !== null && matchScore.home !== '' && getAllAmount('goals', matchData.home_team_id, 'autogoals') !== parseInt(matchScore.home)){
      return (
        <div className="matchResultsWarningWrapper">
          <div className="matchResultsWarningContent">
            <div className="matchResultsWarningRow">
              <FiAlertTriangle size="1.5em" color="#8A6D3B"/>
              <span className="bottomModalHeading">Предупреждение</span>
            </div>
            <div className="matchResultsWarningRow">
              <span className="bottomModalText">Количество голов команды {matchData.home_team_name} ({getAllAmount('goals', matchData.home_team_id, 'autogoals')}) не совпадает с счетом ({matchScore.home})</span>
            </div>
          </div>
        </div>
      )
    } else if(matchData && matchData.guest_team_id && matchScore.guest !== null && matchScore.guest !== '' && getAllAmount('goals', matchData.guest_team_id, 'autogoals') !== parseInt(matchScore.guest)){
      return (
        <div className="matchResultsWarningWrapper">
          <div className="matchResultsWarningContent">
            <div className="matchResultsWarningRow">
              <FiAlertTriangle size="1.5em" color="#8A6D3B"/>
              <span className="bottomModalHeading">Предупреждение</span>
            </div>
            <div className="matchResultsWarningRow">
              <span className="bottomModalText">Количество голов команды {matchData.guest_team_name} ({getAllAmount('goals', matchData.guest_team_id, 'autogoals')}) не совпадает с счетом ({matchScore.guest})</span>
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div className="emptyWarningBox"/>
      )
    }
  }

  const renderSubs = (team_id) => {
    if(matchApplication && matchApplication.length>0){
      return matchApplication.map((player, index) => {
        if(player.team_id == team_id || player.isDouble == team_id){
          return (
            <tr className="matchCenterRow" key={team_id + '_' + index}>
              <td className="matchSubsColumn">{player.player_number}</td>
          <td className="matchSubsPlayer">
            <PlayerMenu
              text={player.family + ' ' + player.name + ' ' + (player.isDouble == team_id ?'(Д)' :'') + ' ' + (player.mvp != null ?'(MVP)' :'') + ' ' + (player.match_position == 4 ?'(ВРТ)' :'')}
              changeMvpState={()=>changeMvpState(player)}
              changeKeeperState={()=>changeKeeperState(player)}
            /></td>
              <td className="matchSubsColumn" onClick={()=>changeActivity(index)}>
                <input
                  name="Присутсвие"
                  type="checkbox"
                  checked={player.active}
                  onChange={()=>{}}/>
              </td>
              <td className="matchSubsColumn" onClick={()=>focusInput(index + '_goals')}>
                <input className="matchColumnInput" ref={setRef(index + '_goals')} placeholder="" onChange={(event)=>changeEvents(event, index, 'goals')} type="text" name="Голы" value={player.goals !== false && player.goals !== null ?player.goals :''}/>
              </td>
              <td className="matchSubsColumn" onClick={()=>focusInput(index + '_assists')}>
                <input className="matchColumnInput" ref={setRef(index + '_assists')} placeholder="" onChange={(event)=>changeEvents(event, index, 'assists')} type="text" name="Голевые передачи" value={player.assists !== false && player.assists !== null ?player.assists :''}/>
              </td>
              <td className="matchSubsColumn" onClick={()=>focusInput(index + '_autogoals')}>
                <input className="matchColumnInput" ref={setRef(index + '_autogoals')} placeholder="" onChange={(event)=>changeEvents(event, index, 'autogoals')} type="text" name="Автоголы" value={player.autogoals !== false && player.autogoals !== null ?player.autogoals :''}/>
              </td>
              <td className="matchSubsColumn" onClick={()=>focusInput(index + '_yellow_cards')}>
                <input className="matchColumnInput" ref={setRef(index + '_yellow_cards')} placeholder="" onChange={(event)=>changeEvents(event, index, 'yellow_cards')} type="text" name="Желтые карточки" value={player.yellow_cards !== false && player.yellow_cards !== null ?player.yellow_cards :''}/>
              </td>
              <td className="matchSubsColumn" onClick={()=>focusInput(index + '_red_cards')}>
                <input className="matchColumnInput" ref={setRef(index + '_red_cards')} placeholder="" onChange={(event)=>changeEvents(event, index, 'red_cards')} type="text" name="Красные карточки" value={player.red_cards !== false && player.red_cards !== null ?player.red_cards :''}/> 
              </td>
            </tr>
          )
        }
      })
    } else {
      return (
        <tr>
          <td colSpan="8" className="emptyRow">
          <span className="emptyRowText">Состав команды не заявлен</span>
          </td>
        </tr>

      )
    }
  }

  const Calendar = React.forwardRef((props, ref) => {
    return <div className={`matchAdditionalInfoCalendarWrapper`} ref={ref}><CustomCalendar {...props} currentDate={currentDate.toDate()} 
    changeDate={(e) => changeDate(moment(e))}/></div>
  })


  const changeScore = (event, input_type) => {
    setMatchScore((prevState => ({
      ...prevState,
      [input_type]: event.target.value.replace(/[^0-9\.]/g, ''),
    })
    ))
  }

  const changeScoreLoseScore = (event, input_type) => {
    setMatchTechLoseScore((prevState => ({
      ...prevState,
      [input_type]: event.target.value.replace(/[^0-9\.]/g, ''),
    })
    ))
  }

  const fuzzySearch = (options) => {
    const fuse = new Fuse(options, {
      keys: ['name'],
      threshold: 0.3,
  });

  return (value) => {
      if (!value.length) {
          return options;
      }
      return fuse.search(value).map(item => item.item);

  };
  }

  const renderMatchAdditionalInfo = () => {
    return (
      <div className="matchAdditionalInfoWrapper">
        <div className="matchAdditionalInfoRow">
          <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Стадион</span>
            <SelectSearch
              options={stadiumsData}
              search
              value={stadium}
              filterOptions={fuzzySearch}
              onChange={(x)=>setStadium(x)}
              emptyMessage="Стадион не найден"
              placeholder="Стадион"/>  
          </div>
          <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Судья</span>
            <SelectSearch
              options={refereesData}
              search
              multiple
              printOptions={'on-focus'}
              value={referee}
              filterOptions={fuzzySearch}
              onChange={(x)=>setReferee(x)}
              emptyMessage="Судья не найден"
              placeholder="Судья"/>
          </div>
          <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Дата и время</span>
            <div className="matchAdditionalInfoDateTimeWrapper">
              <div className="matchAdditionalInfoDateWrapper" onClick={openCalendar}>
                <span className="matchAdditionalInfoDate">{currentDate.format('DD.MM.YYYY')}</span>
              </div>
              <div className="matchAdditionalInfoDateTimeDevider"/>
              <div className="matchAdditinalInfoTimeWrapper">
                <input className="matchAdditionalInfoTimeInput" maxLength = "2" ref={setRef('hours_input')} placeholder="" pattern="[0-9]*" onChange={(event)=>changeDateTime(event, 'hours')} type="text" name="Часы" value={hours}/>
                <span className="matchAdditionalInfoTime">:</span>
                <input className="matchAdditionalInfoTimeInput" maxLength = "2" ref={setRef('minutes_input')} placeholder="" pattern="[0-9]*" onChange={(event)=>changeDateTime(event, 'minutes')} type="text" name="Минуты" value={minutes}/>
              </div>
            </div>
          </div>
          <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Время событий</span>
            <input
                  name="Показывать время событий"
                  type="checkbox"
                  style={{marginTop:8,marginBottom:15,width:20,height:20,alignSelf:'center'}}
                  checked={timelineDis}
                  onChange={(state)=>setTimelineDis(state.target.checked)}/>
          </div>
        </div>


        {
        isComponentVisible && 
        <Calendar 
          ref={ref} />
      }
      </div>
    )
  }


  const renderContent = () => {
    if(!loading && matchData && !_.isEmpty(matchData)){
      return (
          <div style={{width:'100%'}}>
            
            <div className="matchChangeResultsButtonContainerWrapper">
            <div className="matchChangeResultsButtonContainer">
                        <div onClick={changeMatchData} className="matchChangeDataResultsButton"  style={{width:'30%'}}>
              <span className="matchChangeResultsButtonText">
                {
                  loadingFetchApplication 
                    ?
                    <Loader
                    type="TailSpin"
                    color="white"
                    height={13}
                    width={13}
                    timeout={0}/>
                    : 'Добавить стадион, судью и время '
                }                
                </span>
            </div>
            <div onClick={()=>setModalTechLose(true)} className={matchData.status == 5 ?"matchChangeDataResultsButtonGreen" :'matchChangeDataResultsButton'} style={{width:150}}>
              <span className="matchChangeResultsButtonText">
                {
                  loadingFetchApplication 
                    ?
                    <Loader
                    type="TailSpin"
                    color="white"
                    height={13}
                    width={13}
                    timeout={0}/>
                    : 'Тех. поражение'
                }                
                </span>
            </div>

            <div onClick={()=>setModalResheduled(true)} className={matchData.resheduled ?"matchChangeDataResultsButtonGreen" :'matchChangeDataResultsButton'} style={{width:150}}>
              <span className="matchChangeResultsButtonText">
                {
                  loadingFetchApplication 
                    ?
                    <Loader
                    type="TailSpin"
                    color="white"
                    height={13}
                    width={13}
                    timeout={0}/>
                    : 'Перенос'
                }                
                </span>
            </div>

            <div onClick={()=>setModalVisible(true)} className="matchChangeDataResultsButton" style={{width:150}}>
              <span className="matchChangeResultsButtonText">
                {
                  loadingFetchApplication 
                    ?
                    <Loader
                    type="TailSpin"
                    color="white"
                    height={13}
                    width={13}
                    timeout={0}/>
                    : 'Комментарий'
                }                
                </span>
            </div>
            </div>
            </div>
            <div className="matchHeading">
              <span className="matchHeadingText">{matchData.league_name}. {matchData.stage_name}</span>
            </div>
            <div className="matchHeading">
              <span className="matchHeadingText">{moment(matchData.date).format("DD/MM/YYYY")} {matchData.time ?matchData.time.substring(matchData.time.length - 3 ,0) : '—'}</span>
            </div>
            <div className="matchTeamsWrapper">
              <div className="matchTeamWrapper">
                <span className="matchTeamText">{matchData.home_team_name}</span>
                {rendeTeamImage(matchData.home_team_image)}   
              </div>
              <div className="matchTeamWrapper matchTeamGuestWrapper">
                {rendeTeamImage(matchData.guest_team_image)}   
                <span className="matchTeamText">{matchData.guest_team_name}</span>
              </div>
              <div className="matchResultWrapper">
                <div className="matchResultInputs">
                  <input className="matchResultInput" onChange={(event)=>changeScore(event, 'home')} type="text" name="Счет" value={matchScore.home ?matchScore.home :''}/>
                  <span className="matchResultDivider">—</span>
                  <input className="matchResultInput" onChange={(event)=>changeScore(event, 'guest')} type="text" name="Счет" value={matchScore.guest ?matchScore.guest :''}/>
                </div>
              </div>
            </div>
            <div className="matchSubsTablesWrapper">
              <div className="tableWrapper matchSubsTable">
                <div className="table-responsive matchSubsTableOne">
                  <table className="table matchSubsOneTable">
                    <thead>
                      <tr>
                        <th className="matchSubsColumn">№</th>
                        <th className="matchSubsPlayer">Игрок</th>
                        <th className="matchSubsColumn">П*</th>
                        <th className="matchSubsColumn">Гол</th>
                        <th className="matchSubsColumn">Пас</th>
                        <th className="matchSubsColumn">АГ</th>
                        <th className="matchSubsColumn">ЖК</th>
                        <th className="matchSubsColumn">КК</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderSubs(matchData.home_team_id)}
                      <tr className="matchCenterRow">
                        <td className="matchSubsColumn"></td>
                        <td className="matchSubsPlayer">Всего</td>
                        <td onClick={()=>chooseAll(matchData.home_team_id)} className="matchSubsColumn">
                          <span >{getAllAmount(false,matchData.home_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('goals', matchData.home_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('assists', matchData.home_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('autogoals', matchData.home_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('yellow_cards', matchData.home_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('red_cards', matchData.home_team_id)}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="tableWrapper matchSubsTable">
                <div className="table-responsive matchSubsTableOne">
                <table className="table matchSubsOneTable">
                    <thead>
                      <tr>
                        <th className="matchSubsColumn">№</th>
                        <th className="matchSubsPlayer">Игрок</th>
                        <th className="matchSubsColumn">П*</th>
                        <th className="matchSubsColumn">Гол</th>
                        <th className="matchSubsColumn">Пас</th>
                        <th className="matchSubsColumn">АГ</th>
                        <th className="matchSubsColumn">ЖК</th>
                        <th className="matchSubsColumn">КК</th>
                      </tr>
                    </thead>
                    <tbody>
                    {renderSubs(matchData.guest_team_id)}
                    <tr className="matchCenterRow">
                        <td className="matchSubsColumn"></td>
                        <td className="matchSubsPlayer">Всего</td>
                        <td className="matchSubsColumn">
                          <div onClick={()=>chooseAll(matchData.guest_team_id)}>
                            <span>{getAllAmount(false,matchData.guest_team_id)}</span>
                          </div>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('goals', matchData.guest_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('assists', matchData.guest_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('autogoals', matchData.guest_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('yellow_cards', matchData.guest_team_id)}</span>
                        </td>
                        <td className="matchSubsColumn">
                          <span>{getAllAmount('red_cards', matchData.guest_team_id)}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      )
    } else {

      return (
        <div className="loadingOverlay">
          <div className="loadingWrapper">
            <Loader
              type="Rings"
              color="#04a9f5"
              height={40}
              width={40}
              timeout={0}/>
            <span style={{marginLeft:10}}>Загрузка матча</span>
          </div>
        </div>
      )
    }
  }


  return (
    <div className="layoutWrapper">
        <div className="matchCenterWrapper">
          <div className="matchResultsHeader">
            <span className="matchResultsName">Матч. Изменение результатов.</span>
            <div onClick={changeResults} className="matchChangeResultsButton">
              <span className="matchChangeResultsButtonText">
                {
                  loadingFetchApplication 
                    ?
                    <Loader
                    type="TailSpin"
                    color="white"
                    height={13}
                    width={13}
                    timeout={0}/>
                    : 'Внести результат'
                }                
                </span>
            </div>
          </div>
          {renderWarning()}
          {renderMatchAdditionalInfo()}
          {renderContent()}
          <div className="matchResultsHintWrapper">
            <span className="matchResultsHint">П - Присутствие на матче</span>
          </div>
        </div>
        <div ref={modalRef} className="matchResultsModalWrapper">
          <BottomModal text={modalText} type={modalType}/>
        </div>
        <CustomModal
          styles={{width:480, height:'auto',paddingLeft:50, paddingRight:50}}
          visible={modalVisible}
          closeModal={toggleModalComment}>
          <div>
            <div className="customModalHeaderTwo">
              Оставить комментарий к матчу
            </div>
            <textarea
            type="text" 
            className="manageApplicationPlayerCommentInput"
            value={matchComment} 
            placeholder="Комментарий"
            onChange={e => {setMatchComment(e.target.value)}}/>
            <div className="manageApplicationButtonsModalWrapper">
              <div onClick={()=>{
                if(matchComment && matchComment.length){
                  setMatchExtraDataAsync()}
                }
                } className={matchComment && matchComment.length ?"matchChangeResultsButton" :"matchChangeResultsButtonDisabled"}>
                <span className="matchChangeResultsButtonText">
                  Добавить              
                </span>
              </div>
              <div onClick={()=>{
                setModalVisible(false)
              }} style={{backgroundColor:'rgb(253,54,71,1)'}} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Отменить              
                </span>
              </div>
            </div>
          </div>
        </CustomModal>

        <CustomModal
          styles={{width:480, height:'auto',paddingLeft:50, paddingRight:50}}
          visible={modalResheduled}
          closeModal={toggleModalResheduled}>
          <div>
            <div className="customModalHeaderTwo">
              Отметить матч перенесенным
            </div>
            <textarea
            type="text" 
            className="manageApplicationPlayerCommentInput"
            value={matchComment} 
            placeholder="Укажите причину или дату переноса при необходимости"
            onChange={e => {setMatchComment(e.target.value)}}/>
            <div className="manageApplicationButtonsModalWrapper">
              <div onClick={()=>{
                  setMatchExtraDataAsync(true)}
                } className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Далее              
                </span>
              </div>
              <div onClick={()=>{
                setModalResheduled(false)
              }} style={{backgroundColor:'rgb(253,54,71,1)'}} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Отменить              
                </span>
              </div>
            </div>
          </div>
        </CustomModal>

        <CustomModal
          styles={{width:480, height:'auto',paddingLeft:50, paddingRight:50}}
          visible={modalTechLose}
          closeModal={toggleModalTechLose}>
          <div>
            <div className="customModalHeaderTwo">
              Тех. поражение
            </div>

            <div className="matchTeamsWrapper">
              <div className="matchTeamWrapper">
                {rendeTeamImage(matchData.home_team_image)}   
              </div>
              <div className="matchTeamWrapper matchTeamGuestWrapper">
                {rendeTeamImage(matchData.guest_team_image)}   
              </div>
              <div className="matchResultWrapper">
                <div className="matchResultInputs">
                  <input className="matchResultInput" onChange={(event)=>changeScoreLoseScore(event, 'home')} type="text" name="Счет" value={matchTechLoseScore.home}/>
                  <span className="matchResultDivider">—</span>
                  <input className="matchResultInput" onChange={(event)=>changeScoreLoseScore(event, 'guest')} type="text" name="Счет" value={matchTechLoseScore.guest}/>
                </div>
              </div>
              </div>
              <textarea
            type="text" 
            className="manageApplicationPlayerCommentInput"
            value={matchComment} 
            placeholder="Укажите причину технического поражения при необходимости"
            onChange={e => {setMatchComment(e.target.value)}}/>

            <div className="manageApplicationButtonsModalWrapper">
              <div onClick={()=>{
                if((matchTechLoseScore.home !== '0' && matchTechLoseScore.home.length) ||(matchTechLoseScore.guest !== '0' && matchTechLoseScore.guest.length)){
                  setMatchExtraDataAsync(false,true)}
                }
                } className={(matchTechLoseScore.home !== '0' && matchTechLoseScore.home.length) ||(matchTechLoseScore.guest !== '0' && matchTechLoseScore.guest.length) ?"matchChangeResultsButton" :"matchChangeResultsButtonDisabled"}>
                <span className="matchChangeResultsButtonText">
                  Добавить              
                </span>
              </div>
              <div onClick={()=>{
                setModalTechLose(false)
              }} style={{backgroundColor:'rgb(253,54,71,1)'}} className="matchChangeResultsButton">
                <span className="matchChangeResultsButtonText">
                  Отменить              
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
    </div>
  );
}