import React from "react";
import defaultAvatar from "../images/defaultAvatar.png";
import { BASE_URL } from "../constants/config";
import { FiLogOut } from "react-icons/fi";


function DropdownMenu(props) {

  const renderUserImage = () => {
    if(props && props.userData && props.userData.image){
      return (
        <img src={BASE_URL.url + props.userData.image} className="dropdownAvatar"/>    
      )
    } else {
      return (
        <img src={defaultAvatar} className="dropdownAvatar"/>
      )
    }
  }

  if(props.visible){
    return (
      <div className="dropDownMenu">
        <div className="dropDownUserOverlay">
          <div className="dropDownUserWrapper">
            <div className="dropDownUserConatiner">
            {renderUserImage()}
            <div className="dropDownUserName">{props.userData.name} {props.userData.family}</div>
            </div>
          </div>
        </div>
        <div className="dropDownButtonOverlay">
          <div onClick={props.logOut}  className="dropDownButton">
            <FiLogOut color="#959899" size="0.9em"/>
            <div className="dropDownButtonText">Выйти</div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default DropdownMenu;