import {FETCH_ROSTER_SUCCESS, FETCH_ROSTER_FAILURE, FETCH_ROSTER_BEGIN,
  FETCH_ADMIN_TEAMS_BEGIN,FETCH_ADMIN_TEAMS_SUCCESS,FETCH_ADMIN_TEAMS_FAILURE,} from '../constants/action-types';
import {BASE_URL, API_URLS} from '../constants/config'
import {refreshTokens, logOut} from './usersActions'
import {checkIfTokenExpired, getTokensFromStore } from '../utilities/tokens'
import axios from 'axios';
let token;

export const fetchRosterBegin = () => ({
  type: FETCH_ROSTER_BEGIN
});

export const fetchRosterSuccess = roster => ({
  type: FETCH_ROSTER_SUCCESS,
  payload: { roster }
});

export const fetchRosterFailure = error => ({
  type: FETCH_ROSTER_FAILURE,
  payload: { error }
});

export const fetchAdminTeamsBegin = () => ({
  type: FETCH_ADMIN_TEAMS_BEGIN
});

export const fetchAdminTeamsSuccess = teamsData => ({
  type: FETCH_ADMIN_TEAMS_SUCCESS,
  payload: { teamsData }
});

export const fetchAdminTeamsFailure = error => ({
  type: FETCH_ADMIN_TEAMS_FAILURE,
  payload: { error }
});

export function fetchAdminTeams(url,search, league_id) {
  return async dispatch => {
    if(token){
      // Cancel the previous request before making a new request
      token.cancel()
    }
    token = axios.CancelToken.source()
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(fetchAdminTeamsBegin());
    return axios.post(BASE_URL.url + url,{
      search,
      leagueId: league_id
    },
    {
      headers: {
        "Authorization" : "Bearer " + tokens.access_token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      cancelToken: token.token
    }).then(res => {
          dispatch(fetchAdminTeamsSuccess(res));
          return res;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('post Request canceled');
          } else {
            dispatch(fetchAdminTeamsFailure(error.response))
            if(error.response.status == 401) {
              dispatch(logOut())
            }          }
        });
  };
}

export function fetchRoster(team_id) {
  let url = API_URLS.getRoster;
  url = url.replace(':teamId', team_id);
  return dispatch => {
    dispatch(fetchRosterBegin());
    return axios.get(BASE_URL.url + url).then(res => {
      dispatch(fetchRosterSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchRosterFailure(error.response))
        });
  };
}

