import { createStore, combineReducers, applyMiddleware } from "redux";
import { LOG_OUT_SUCCESS} from '../constants/action-types';
import matchesReducer from '../reducers/matchesReducer';
import { persistStore, persistReducer } from 'redux-persist'
import usersReducer from '../reducers/usersReducer'
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage';
import stadiumReducer from '../reducers/stadiumsReducer';
import judgeReducer from '../reducers/judgesReducer';
import applicationsReducer from '../reducers/applicationsReducer';
import leaguesReducer from '../reducers/leaguesReducer';
import teamsReducer from '../reducers/teamsReducer';
import blackListReducer from '../reducers/blackListReducer';
import playerReducer from '../reducers/playersReducer';


const persistConfig = {
  key: 'authType',
  storage: storage,
  whitelist: ['user'] // which reducer want to store
};

const appReducer = combineReducers(
  {
    matches: matchesReducer,
    user: usersReducer,
    stadium: stadiumReducer,
    judge: judgeReducer,
    applications: applicationsReducer,
    leagues: leaguesReducer,
    team: teamsReducer,
    blacklist: blackListReducer,
    player: playerReducer,

  }
);

const rootReducer = ( state, action ) => {
  if ( action.type === LOG_OUT_SUCCESS ) {
    state = undefined;
  }
  return appReducer(state, action)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = createStore(persistedReducer, applyMiddleware(thunk));

export default store;
export const persistor = persistStore(store);