import { 
  FETCH_AVAILABLE_TEAMS_APPLICATIONS_BEGIN,FETCH_AVAILABLE_TEAMS_APPLICATIONS_SUCCESS,FETCH_AVAILABLE_TEAMS_APPLICATIONS_FAILURE,
  FETCH_TEAM_APPLICATION_QUERY_BEGIN, FETCH_TEAM_APPLICATION_QUERY_SUCCESS, FETCH_TEAM_APPLICATION_QUERY_FAILURE,
  FETCH_PLAYER_APPLICATIONS_BEGIN, FETCH_PLAYER_APPLICATIONS_SUCCESS, FETCH_PLAYER_APPLICATIONS_FAILURE,
  DECLINE_APPLICATION_BEGIN, DECLINE_APPLICATION_SUCCESS, DECLINE_APPLICATION_FAILURE,
  ACCEPT_APPLICATION_BEGIN, ACCEPT_APPLICATION_SUCCESS, ACCEPT_APPLICATION_FAILURE
} from '../constants/action-types';
import {BASE_URL, API_URLS} from '../constants/config'
import axios from 'axios';
import {refreshTokens, logOut} from './usersActions'
import {checkIfTokenExpired, getTokensFromStore } from '../utilities/tokens'

export const fetchTeamApplicationQueryBegin = () => ({
  type: FETCH_TEAM_APPLICATION_QUERY_BEGIN
});

export const fetchTeamApplicationQuerySuccess = application => ({
  type: FETCH_TEAM_APPLICATION_QUERY_SUCCESS,
  payload: { application }
});

export const fetchTeamApplicationQueryFailure = error => ({
  type: FETCH_TEAM_APPLICATION_QUERY_FAILURE,
  payload: { error }
});

export const fetchTeamsApplicationsBegin = () => ({
  type: FETCH_AVAILABLE_TEAMS_APPLICATIONS_BEGIN
});

export const fetchTeamsApplicationsSuccess = applications => ({
  type: FETCH_AVAILABLE_TEAMS_APPLICATIONS_SUCCESS,
  payload: { applications }
});

export const fetchTeamsApplicationsFailure = error => ({
  type: FETCH_AVAILABLE_TEAMS_APPLICATIONS_FAILURE,
  payload: { error }
});

export const fetchPlayerApplicationsBegin = () => ({
  type: FETCH_PLAYER_APPLICATIONS_BEGIN
});

export const fetchPlayerApplicationsSuccess = applications => ({
  type: FETCH_PLAYER_APPLICATIONS_SUCCESS,
  payload: { applications }
});

export const fetchPlayerApplicationsFailure = error => ({
  type: FETCH_PLAYER_APPLICATIONS_FAILURE,
  payload: { error }
});

export const declineApllicationBegin = () => ({
  type: DECLINE_APPLICATION_BEGIN
});

export const declineApllicationSuccess = applications => ({
  type: DECLINE_APPLICATION_SUCCESS,
  payload: { applications }
});

export const declineApllicationFailure = error => ({
  type: DECLINE_APPLICATION_FAILURE,
  payload: { error }
});

export const acceptApllicationBegin = () => ({
  type: ACCEPT_APPLICATION_BEGIN
});

export const acceptApllicationSuccess = applications => ({
  type: ACCEPT_APPLICATION_SUCCESS,
  payload: { applications }
});

export const acceptApllicationFailure = error => ({
  type: ACCEPT_APPLICATION_FAILURE,
  payload: { error }
});

export function declineApllication(application_id, application_data) {
  const url = API_URLS.declineApplication
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(declineApllicationBegin());
    return axios.post(BASE_URL.url + url,{
      application_id,
      application_data
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(declineApllicationSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(declineApllicationFailure(error.response))
          if(error && error.response && error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}

export function acceptApllication(application_id, application_data) {
  const url = API_URLS.acceptApplication
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(acceptApllicationBegin());
    return axios.post(BASE_URL.url + url,{
      application_id,
      application_data
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(acceptApllicationSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(acceptApllicationFailure(error.response))
          if(error && error.response && error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}

export function fetchPlayerApplications(player_id) {
  let url = API_URLS.getPlayerApplications
  url = url.replace(":playerId", player_id);
  return dispatch => {
    dispatch(fetchPlayerApplicationsBegin());
    return axios.get(BASE_URL.url + url).then(res => {
      dispatch(fetchPlayerApplicationsSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchPlayerApplicationsFailure(error.response))
        });
  };
}

export function fetchTeamApplicationQuery(application_id) {
  const url = API_URLS.getTeamApplication
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(fetchTeamApplicationQueryBegin());
    return axios.post(BASE_URL.url + url,{
      application_id
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(fetchTeamApplicationQuerySuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchTeamApplicationQueryFailure(error.response))
          if(error && error.response && error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}

export function fetchTeamsApplications(isOnlyNew, league_id) {
  const url = API_URLS.getAvailableTeamApplications
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(fetchTeamsApplicationsBegin());
    return axios.post(BASE_URL.url + url,{
        status: isOnlyNew ?2 :false,
        league_id: league_id?league_id : false,
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(fetchTeamsApplicationsSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchTeamsApplicationsFailure(error.response))
          if(error && error.response && error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}