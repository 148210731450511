import { FETCH_ALL_MATCHES_BEGIN, FETCH_ALL_MATCHES_SUCCESS, FETCH_ALL_MATCHES_FAILURE,
  FETCH_MATCH_BEGIN, FETCH_MATCH_SUCCESS, FETCH_MATCH_FAILURE,
  FETCH_MATCH_ACTIVE_APPLICATION_BEGIN, FETCH_MATCH_ACTIVE_APPLICATION_SUCCESS, FETCH_MATCH_ACTIVE_APPLICATION_FAILURE,
  SET_MATCH_ALL_RESULTS_BEGIN, SET_MATCH_ALL_RESULTS_SUCCESS, SET_MATCH_ALL_RESULTS_FAILURE,
  SET_MATCH_EXTRA_DATA_BEGIN, SET_MATCH_EXTRA_DATA_SUCCESS, SET_MATCH_EXTRA_DATA_FAILURE,
  SET_MATCH_BEST_PLAYER_BEGIN, SET_MATCH_BEST_PLAYER_SUCCESS, SET_MATCH_BEST_PLAYER_FAILURE,
  UNSET_MATCH_BEST_PLAYER_BEGIN, UNSET_MATCH_BEST_PLAYER_SUCCESS, UNSET_MATCH_BEST_PLAYER_FAILURE
} from '../constants/action-types';
import {BASE_URL} from '../constants/config'
import axios from 'axios';
import {refreshTokens, logOut} from './usersActions'
import {checkIfTokenExpired, getTokensFromStore } from '../utilities/tokens'

export const fetchMatchBegin = () => ({
  type: FETCH_MATCH_BEGIN
});

export const fetchMatchSuccess = matchData => ({
  type: FETCH_MATCH_SUCCESS,
  payload: { matchData }
});

export const fetchMatchFailure = error => ({
  type: FETCH_MATCH_FAILURE,
  payload: { error }
});

export const fetchMatchesBegin = () => ({
  type: FETCH_ALL_MATCHES_BEGIN
});

export const fetchMatchesSuccess = matchData => ({
  type: FETCH_ALL_MATCHES_SUCCESS,
  payload: { matchData }
});

export const fetchMatchesFailure = error => ({
  type: FETCH_ALL_MATCHES_FAILURE,
  payload: { error }
});

export const fetchMatchActiveApplicationBegin = () => ({
  type: FETCH_MATCH_ACTIVE_APPLICATION_BEGIN
});

export const fetchMatchActiveApplicationSuccess = matchActiveApplication => ({
  type: FETCH_MATCH_ACTIVE_APPLICATION_SUCCESS,
  payload: { matchActiveApplication }
});

export const fetchMatchActiveApplicationFailure = error => ({
  type: FETCH_MATCH_ACTIVE_APPLICATION_FAILURE,
  payload: { error }
});

export const setMatchAllResultsBegin = () => ({
  type: SET_MATCH_ALL_RESULTS_BEGIN
});

export const setMatchAllResultsSuccess = matchData => ({
  type: SET_MATCH_ALL_RESULTS_SUCCESS,
  payload: { matchData }
});

export const setMatchAllResultsFailure = error => ({
  type: SET_MATCH_ALL_RESULTS_FAILURE,
  payload: { error }
});

export const setMatchExtraDataBegin = () => ({
  type: SET_MATCH_EXTRA_DATA_BEGIN
});

export const setMatchExtraDataSuccess = matchData => ({
  type: SET_MATCH_EXTRA_DATA_SUCCESS,
  payload: { matchData }
});

export const setMatchExtraDataFailure = error => ({
  type: SET_MATCH_EXTRA_DATA_FAILURE,
  payload: { error }
});

export const setMatchBestPlayerBegin = () => ({
  type: SET_MATCH_BEST_PLAYER_BEGIN
});

export const setMatchBestPlayerSuccess = players => ({
  type: SET_MATCH_BEST_PLAYER_SUCCESS,
  payload: { players }
});

export const setMatchBestPlayerFailure = error => ({
  type: SET_MATCH_BEST_PLAYER_FAILURE,
  payload: { error }
});

export const unsetMatchBestPlayerBegin = () => ({
  type: UNSET_MATCH_BEST_PLAYER_BEGIN
});

export const unsetMatchBestPlayerSuccess = players => ({
  type: UNSET_MATCH_BEST_PLAYER_SUCCESS,
  payload: { players }
});

export const unsetMatchBestPlayerFailure = error => ({
  type: UNSET_MATCH_BEST_PLAYER_FAILURE,
  payload: { error }
});


export function setMatchBestPlayer(url, data, match_id) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(setMatchBestPlayerBegin());
    return axios.post(BASE_URL.url + url,{
          data,
          match_id
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(setMatchBestPlayerSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(setMatchBestPlayerFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}

export function unsetMatchBestPlayer(url, data, match_id) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(unsetMatchBestPlayerBegin());
    return axios.post(BASE_URL.url + url,{
      data,
      match_id
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(unsetMatchBestPlayerSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(unsetMatchBestPlayerFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}


export function setMatchExtraData(url, matchId, data) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(setMatchExtraDataBegin());
    return axios.post(BASE_URL.url + url,{
      match_id: matchId,
      data,
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(setMatchExtraDataSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(setMatchExtraDataFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}


export function setMatchAllResults(url, matchId, matchApplication, matchEvents, matchData) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(setMatchAllResultsBegin());
    return axios.post(BASE_URL.url + url,{
      match_id: matchId,
      matchData,
      matchApplication,
      matchEvents
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(setMatchAllResultsSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(setMatchAllResultsFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}


export function fetchAllMatches(url, date, condition, limit, offset) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(fetchMatchesBegin());
    return axios.post(BASE_URL.url + url,{
          date,
          condition,
          limit,
          offset
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(fetchMatchesSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchMatchesFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}

export function fetchMatch(url) {
  return dispatch => {
    dispatch(fetchMatchBegin());
    return axios.get(BASE_URL.url + url).then(res => {
      dispatch(fetchMatchSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchMatchFailure(error.response))
        });
  };
}

export function fetchMatchActiveApplication(url, teams_id, match_id, condition) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(fetchMatchActiveApplicationBegin());
    return axios.post(BASE_URL.url + url,{
          match_id: match_id,
          team_id: teams_id,
          with_doubles: true
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(fetchMatchActiveApplicationSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchMatchActiveApplicationFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}