import { FaEdit,FaEye } from "react-icons/fa";
import {FiEdit2} from "react-icons/fi";
import {BiEdit, BiEditAlt} from "react-icons/bi";
import { BASE_URL } from "../constants/config";
import {IoMdClose, IoMdEye} from "react-icons/io";
import Collapse from "@kunukn/react-collapse";
import moment from 'moment'
import 'moment/locale/ru';

const processValue = (value) => {
  let checked_value =  'Не указано';
  if(value){
    checked_value = value;
  }
  return checked_value;
}

const processDate = (value) => {
  let day, month = '';
  if(value && moment(value)){
      const moment_day = moment(value);
      day = moment_day.format('D');
      month = moment_day.format('MMM');
      return day + ' ' + month
  }
  else return 'Не указано'
}


const BlackListPlayer = ({data, collapsedPlayersids, onPress, deletePlayer}) => (
  <div className="blacklistPlayerContainerx shadow">  
    <div id={"black_list_player_menu_" + data.id} className="blackListPlayerTopMenuWrapper">
    <div className="blackListPlayerTopMenu">
      <button className="blackListPlayerTopMenuLeftButton shadow" onClick={()=>onPress(data.id)}>
        <IoMdEye size="1.8em" color="rgba(0,0,0,1)"/>

      </button>
      <div className="blackListPlayerTopMenuLeftButton shadow" onClick={()=>deletePlayer(data.player_id)}>
      <IoMdClose size="1.8em" color="rgba(0,0,0,1)"/>

        </div>
    </div>
  </div>
  <div id={"black_list_player_" + data.id} className="blacklistPlayerContainer blacklistPlayerContainerBrightness">  
    {/* <div className="blacklistPlayerWrapper"> */}
      <div className="blacklistPLayerPhotoWrapper">
        <img src={BASE_URL.url + data.image} className="blacklistPlayerPhoto"/>  
      </div>
      <div id={"black_list_player_collapsible" + data.id} className={'blackListCollapsiblePart'}>
        <div id={"black_list_player_menu_cross_" + data.id} className="blackListPlayerCollapsibleButtonWrapper">
          <button className="blackListPlayerCollapsibleButton shadow" onClick={()=>onPress(data.id)}>
            <IoMdClose size="1.2em" color="rgba(0,0,0,1)"/>
          </button>
        </div>
      <div className="blackListPlayerInfoWrapper">
        <div className="blackListPlayerFamilyWrapper">
          <span className="blacklistPlayerFamilyText blacklistPlayerFamilyTextBold">{data.family}</span>
          <span className="blacklistPlayerFamilyText">{data.name}</span>
        </div>
        <div className="blacklistPlayerBlockData">
          <div className="blackListPlayerOneBlock">
            <div className="blackListPlayerOneBlockLabelWrapper">
              <span className="blackListPlayerOneBlockLabel">Дата</span>
            </div>
            <div className="blackListPlayerOneBlockValueWrapper">
              <span className="blackListPlayerOneBlockValue">{processDate(data.date)}</span>
            </div>
          </div>
          <div className="blacklistPlayerLine"/>

          <div className="blackListPlayerOneBlock">
            <div className="blackListPlayerOneBlockLabelWrapper">
              <span className="blackListPlayerOneBlockLabel">Причина</span>
            </div>
            <div className="blackListPlayerOneBlockValueWrapper">
              <span className="blackListPlayerOneBlockValue">{processValue(data.comment)}</span>
            </div>
          </div>
          <div className="blacklistPlayerLine"/>

          <div className="blackListPlayerOneBlock">
            <div className="blackListPlayerOneBlockLabelWrapper">
              <span className="blackListPlayerOneBlockLabel">Цена</span>
            </div>
            <div className="blackListPlayerOneBlockValueWrapper">
              <span className="blackListPlayerOneBlockValue">{processValue(data.price)}</span>
            </div>
          </div>
          <div className="blacklistPlayerLine"/>
        </div>
        </div>
      </div>
    {/* </div> */}

  </div>

  </div>

);

export default BlackListPlayer;