import jwtDecode from 'jwt-decode';
import store from './store';

export const getExpiredDate = (userData) => {
  if(userData && userData.access_token){
    const decoded = jwtDecode(userData.access_token);
    if(decoded && decoded.exp) return decoded.exp;
    return false
  }
  return false
};

export const checkIfTokenExpired = () => {
  if(store && store.getState() && store.getState().user && store.getState().user.userData && store.getState().user.userData.data && store.getState().user.userData.data.access_token_expire){
    const tokenExp = store.getState().user.userData.data.access_token_expire;
    if ((Date.now() + 5000) >= tokenExp * 1000) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const getTokensFromStore = () => {
  if(store && store.getState() && store.getState().user && store.getState().user.userData && store.getState().user.userData.data
      && store.getState().user.userData.data.access_token){ //&& store.getState().user.userData.data.refresh_token) {
    const access_token = store.getState().user.userData.data.access_token;
    const refresh_token = store.getState().user.userData.data.refresh_token;
    return {access_token:access_token, refresh_token: refresh_token}
  }
  return false;
};