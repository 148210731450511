import React, { useState } from "react";
import mockup from "../images/mockup.png";
import {leftTextFirstSlide} from "../constants/config";
import {rightTextFirstSlide} from "../constants/config";

function FirstSlide(props) {

return (
<div className="main">
  <h2 className="leftText">{leftTextFirstSlide}</h2>
  <img src={mockup} className="mockup"/>
  <h2 className="rightText">{rightTextFirstSlide}</h2>
</div>
  );
}

export default FirstSlide;

