import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding: 0,
    borderRadius:  '1vw'
  }
};

Modal.setAppElement(document.getElementById('root'));

function CustomModal(props) {

  return (
    <Modal
    isOpen={props.visible}
    //onAfterOpen={afterOpenModal}
    onRequestClose={props.closeModal}
    style={customStyles}>
      <div className="customModal" style={props.styles ?{...props.styles} : {}}>
        {props.children}
      </div>

  </Modal>
  );
}

export default CustomModal;
