import React, { useState } from "react";
import Header from "../components/Header";
import AuthModal from "../components/AuthModal";
import { useAuth } from "../utilities/auth";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login } from "../actions/usersActions";
import { API_URLS } from "../constants/config";
import FirstSlide from "../components/FirstSlide";
import SecondSlide from "../components/SecondSlide";
import ThirdSlide from "../components/ThirdSlide";
import FourthSlide from "../components/FourthSlide";
import ReactPageScroller from "react-page-scroller";
import { Pagination } from "react-bootstrap";

function Main(props) {
  let referer = "/";
  if (
    props &&
    props.location &&
    props.location.state &&
    props.location.state.referer
  ) {
    referer = props.location.state.referer;
  }
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setUserData } = useAuth();
  const [currentPage, setCurrentPage] = useState("");

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const login = () => {
    props.actions
      .login(API_URLS.login, userName, password)
      .then(response => {
        if (response.status === 200) {
          setUserData(response.data);
          setLoggedIn(true);
        } else {
          setIsError(true);
          setErrorText(response.data ?response.data :'Неизвестная ошибка')
        }
      })
  };

  if (isLoggedIn) {
    return <Redirect to={"/admin"} />;
  }

  return (
      <div className="allapp">
        <Header
          toggleModal={toggleModal}
          setCurrentPage={(pageNumber) => setCurrentPage(pageNumber)}
        />
        <AuthModal
          visible={modalVisible}
          closeModal={toggleModal}
          userName={userName}
          password={password}
          isError={isError}
          errorText={errorText}
          setUserName={setUserName}
          setPassword={setPassword}
          login={login}
        />
        {/* <ReactPageScroller customPageNumber={currentPage}> */}
          <FirstSlide />
          {/* <SecondSlide />
          <ThirdSlide />
          <FourthSlide />
        </ReactPageScroller> */}
      </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ login }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
