import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

function PlayerMenu({text, changeMvpState, changeKeeperState}) {

    const test = (popupState) => {
        popupState.close()
        changeMvpState()
    }

    const changeKeeper = (popupState) => {
        popupState.close()
        changeKeeperState()
    }

    return (
        <PopupState variant="popover" popupId="demoMenu">
            {(popupState) => (
            <React.Fragment>
                <span variant="contained" {...bindTrigger(popupState)}>
                {text}
                </span>
                <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={()=>test(popupState)}>MVP</MenuItem>
                <MenuItem onClick={()=>changeKeeper(popupState)}>Вратарь</MenuItem>
                </Menu>
            </React.Fragment>
            )}
        </PopupState>
    )
}

export default PlayerMenu