import {GET_ALL_REFEREES_BEGIN, GET_ALL_REFEREES_SUCCESS, GET_ALL_REFEREES_FAILURE} from '../constants/action-types';


const initialState = {
  judgesData: {},
  loading: false,
  error: null
};

export default function judgeReducer(state = initialState, action) {
  switch(action.type) {
    case GET_ALL_REFEREES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_ALL_REFEREES_SUCCESS:
      return {
        ...state,
        loading: false,
        judgesData: action.payload.refereesData
      };

    case GET_ALL_REFEREES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        judgesData: {}
      };

    default:
      return state;
  }
}