import {GET_ALL_REFEREES_BEGIN, GET_ALL_REFEREES_SUCCESS, GET_ALL_REFEREES_FAILURE} from '../constants/action-types';
import {BASE_URL} from '../constants/config'
import {refreshTokens, logOut} from './usersActions'
import {checkIfTokenExpired, getTokensFromStore } from '../utilities/tokens'
import axios from 'axios';

export const fetchAllRefereesBegin = () => ({
  type: GET_ALL_REFEREES_BEGIN
});

export const fetchAllRefereesSuccess = refereesData => ({
  type: GET_ALL_REFEREES_SUCCESS,
  payload: { refereesData }
});

export const fetchAllRefereesFailure = error => ({
  type: GET_ALL_REFEREES_FAILURE,
  payload: { error }
});

export function fetchAllReferees(url) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(fetchAllRefereesBegin());
    return axios.post(BASE_URL.url + url,{
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(fetchAllRefereesSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchAllRefereesFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}
