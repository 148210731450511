import {FETCH_ROSTER_SUCCESS, FETCH_ROSTER_FAILURE, FETCH_ROSTER_BEGIN,
  FETCH_ADMIN_TEAMS_BEGIN,FETCH_ADMIN_TEAMS_SUCCESS,FETCH_ADMIN_TEAMS_FAILURE,
} from '../constants/action-types';


const initialState = {
  teamApplications: {},
  adminTeams: {},
  loading: false,
  error: null
};

export default function teamsReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_ROSTER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ROSTER_SUCCESS:
      return {
        ...state,
        loading: false,
        teamApplications: action.payload.roster
      };

    case FETCH_ROSTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        teamApplications: {}
      };

      case FETCH_ADMIN_TEAMS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case FETCH_ADMIN_TEAMS_SUCCESS:
        return {
          ...state,
          loading: false,
          adminTeams: action.payload.teamsData
        };
  
      case FETCH_ADMIN_TEAMS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          adminTeams: {}
        };

    default:
      return state;
  }
}