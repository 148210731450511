import {GET_ALL_STADIUMS_BEGIN, GET_ALL_STADIUMS_SUCCESS, GET_ALL_STADIUMS_FAILURE} from '../constants/action-types';
import {BASE_URL} from '../constants/config'
import axios from 'axios';
import {refreshTokens, logOut} from './usersActions'

import {checkIfTokenExpired, getTokensFromStore } from '../utilities/tokens'

export const fetchAllStadiumsBegin = () => ({
  type: GET_ALL_STADIUMS_BEGIN
});

export const fetchAllStadiumsSuccess = stadiumsData => ({
  type: GET_ALL_STADIUMS_SUCCESS,
  payload: { stadiumsData }
});

export const fetchAllStadiumsFailure = error => ({
  type: GET_ALL_STADIUMS_FAILURE,
  payload: { error }
});

export function fetchAllStadiums(url) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(fetchAllStadiumsBegin());
    return axios.post(BASE_URL.url + url,{
        },
        {
          headers: {
            "Authorization" : "Bearer " + tokens.access_token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(res => {
      dispatch(fetchAllStadiumsSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchAllStadiumsFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}