import {FETCH_ALL_ADMIN_LEAGUES_BEGIN, FETCH_ALL_ADMIN_LEAGUES_SUCCESS, FETCH_ALL_ADMIN_LEAGUES_FAILURE} from '../constants/action-types';
import {BASE_URL, API_URLS} from '../constants/config'
import {refreshTokens, logOut} from './usersActions'
import {checkIfTokenExpired, getTokensFromStore } from '../utilities/tokens'
import axios from 'axios';

export const fetchAllAdminLeaguesBegin = () => ({
  type: FETCH_ALL_ADMIN_LEAGUES_BEGIN
});

export const fetchAllAdminLeaguesSuccess = leaguesData => ({
  type: FETCH_ALL_ADMIN_LEAGUES_SUCCESS,
  payload: { leaguesData }
});

export const fetchAllAdminLeaguesFailure = error => ({
  type: FETCH_ALL_ADMIN_LEAGUES_FAILURE,
  payload: { error }
});

export function fetchAllAdminLeagues() {
  let url = API_URLS.getAllAdminLeagues
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    dispatch(fetchAllAdminLeaguesBegin());
    return axios.get(BASE_URL.url + url,
      {          
        headers: {
        "Authorization" : "Bearer " + tokens.access_token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
      }).then(res => {
      dispatch(fetchAllAdminLeaguesSuccess(res));
      return res;
    })
        .catch((error) => {
          dispatch(fetchAllAdminLeaguesFailure(error.response))
          if(error.response.status == 401) {
            dispatch(logOut())
          }
        });
  };
}
