import {FETCH_ALL_ADMIN_LEAGUES_BEGIN, FETCH_ALL_ADMIN_LEAGUES_SUCCESS, FETCH_ALL_ADMIN_LEAGUES_FAILURE} from '../constants/action-types';


const initialState = {
  leaguesData: {},
  loading: false,
  error: null
};

export default function leaguesReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_ALL_ADMIN_LEAGUES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ALL_ADMIN_LEAGUES_SUCCESS:
      return {
        ...state,
        loading: false,
        leaguesData: action.payload.leaguesData
      };

    case FETCH_ALL_ADMIN_LEAGUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        leaguesData: {}
      };

    default:
      return state;
  }
}