import React from "react";
import { FiMenu, FiChevronDown } from "react-icons/fi";
import defaultAvatar from "../images/defaultAvatar.png";
import { BASE_URL } from "../constants/config";
import DropdownMenu from './DropdownMenu';

function AdminHeader(props) {

  const renderUserImage = () => {
    if(props && props.userData && props.userData.image){
      return (
        <img src={BASE_URL.url + props.userData.image} className="adminHeaderUserImage"/>    
      )
    } else {
      return (
        <img src={defaultAvatar} className="adminHeaderUserImage"/>
      )
    }
  }


  return (
    <div className="adminHeaderWrapper">
      <div className="adminHeader">
        <div className="adminHeaderButtons" onClick={()=>props.toggleSideBar()}>
          <FiMenu size="1.3em" color="#3f4d67"/>
        </div>
        <div onClick={props.toggleDropDown} className="adminHeaderUserBlock">
          {renderUserImage()}
          <FiChevronDown size="1.1em" color="#3f4d67"/>
        </div>
        <div className="dropDownMenuWrapper">
          <DropdownMenu 
            logOut={props.logOut}
            userData={props.userData} 
            visible={props.dropDownVisible}/>
        </div>
      </div>
    </div> 
  );
}

export default AdminHeader;