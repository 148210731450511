import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './api/Router'
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./utilities/store";
import persistor from "./utilities/store";
import { PersistGate } from 'redux-persist/integration/react';


ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
