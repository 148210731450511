import React, { useState } from "react";
import MatchCenter from "../components/MatchCenter";
import { useHistory } from "react-router-dom";

function Admin(props) {
  const history = useHistory();

  const goToMatchResult = (match_id) => {
    history.push("/manage_match/" + match_id);
  }


  return (
    <div className="layoutWrapper">
      <MatchCenter goToMatchResult={goToMatchResult}/>
    </div>
  );
}


export default Admin;