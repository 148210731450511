import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultTeamLogo from "../images/defaultTeamLogo.png";
import { BASE_URL } from "../constants/config";
import Loader from "react-loader-spinner";
import moment from 'moment'
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {fetchRoster} from '../actions/teamsActions'
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      min-width:14vw;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
    }
  }
  `


export default function TeamRosters(props) {
    const history = useHistory();
    const applications = useSelector(state => state.team.teamApplications.data);
    const [applicationList, setApplicationList] = useState([]);
    const [activeApplication, setActiveApplication] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [onlyNew, setOnlyNew] = useState(true);
    

    useEffect(() => {
      _loadAsyncApplications(props.match.params.id)
    }, []);

    const _loadAsyncApplications = (team_id) => {
      dispatch(fetchRoster(team_id)).then(
        roster => {
          if(roster && roster.data && roster.data.applications && roster.data.applications.length){
            if(props.match.params.application_id){
              setActiveApplication((state) => parseInt(props.match.params.application_id))
            }

            const temp_applications = []
            roster.data.applications.map((item, index) => {
              item.key = index;
              item.name = item.application_name
              temp_applications.push(item)
            })
    
            setApplicationList([...temp_applications]) 
          }
        }
    );
    }


  const rendeTeamImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultLogo.png')){
      return (
        <img src={defaultTeamLogo} className="teamRostersTeamImage"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="teamRostersTeamImage"/>  
      )
    }
  }

  // const renderApplicationList = () => {
  //   return applications.map((item, index) => {
  //     return (
  //       <tr onClick={()=>history.push("/manage_application/" + item.cap_application_id)} className="matchCenterRow" key={index}>
  //       <td className="applicationTableTeam">
  //       <div className="applicationTeamWrapper">
  //             {rendeTeamImage(item.team_image)}   
  //             <div className="teamName">{item.team_name}</div>
  //           </div>
  //       </td>
  //       <td className="applicationTableApplicationName">
  //         <div>
  //           {item.application_name}
  //         </div>
  //       </td>
  //       <td className="applicationTableDate">{moment(item.created_at).format("DD/MM/YYYY")}</td>
  //       <td className="applicationTableStatus">
  //         {getStatus(item.status)}
  //         </td>
  //         <div style={{width:'3%', height:70,backgroundColor:getStatusColor(item.status),position:'absolute',left:0}}/>
  //       </tr>
  //     )
  //   })
  // }

  // const renderContent = () => {
  //     if(!loading){
  //       if(applications && applications.length > 0){
  //       return (
  //         <div className="tableWrapper">
  //           <div className="table-responsive">
  //             <table className="table">
  //               <thead>
  //                 <tr>
  //                   <th className="applicationTableTeam">Команда</th>
  //                   <th className="applicationTableApplicationName">Заявка</th>
  //                   <th className="applicationTableDate">Дата</th>
  //                   <th className="applicationTableStatus">Статус</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {renderApplicationList()}
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //       )
  //     } else {
  //       return (
  //         <div className="emptyWrapper">
  //           <span className="emptyText">Похоже заявок нет...</span>
  //         </div>
  //       )
  //     }
  //   } else {

  //     return (
  //       <div className="loadingOverlay">
  //         <div className="loadingWrapper">
  //           <Loader
  //             type="Rings"
  //             color="#04a9f5"
  //             height={40}
  //             width={40}
  //             timeout={0}/>
  //           <span style={{marginLeft:10}}>Загрузка заявок</span>
  //         </div>
  //       </div>
  //     )
  //   }
  // }

  const fuzzySearch = (options) => {
    const fuse = new Fuse(options, {
      keys: ['name'],
      threshold: 0.3,
  });

  return (value) => {
      if (!value.length) {
          return options;
      }
      return fuse.search(value).map(item => item.item);

  };
  }

  const changeLeague = (applic) => {
    //_loadAsyncApplications(onlyNew, league)
    setActiveApplication(applic)
  }
//
  const data = React.useMemo(
    () => applications && applications.rosters && applications.rosters[0] && activeApplication ?applications.rosters.find(ros => ros.application_id == activeApplication).data :[]
    ,[activeApplication])
  

  const columns = React.useMemo(
    () => [
      {
        Header: 'Фамилия',
        accessor: 'family', // accessor is the "key" in the data
      },
      {
        Header: 'Имя',
        accessor: 'name',
      },
      {
        Header: 'Отчество',
        accessor: 'father_name',
      },
      {
        Header: 'Фото',
        accessor: 'image',
        width: 100,
        Cell: ({ cell: { value }}) => (
          <div>
            <img
              src={BASE_URL.url + value} alt={'Фото'} className="teamRostersTablePhoto"
            />
          </div>
        )
      },
      {
        Header: 'Позиция',
        accessor: 'short_position',
      },
      {
        Header: 'Номер',
        accessor: 'number',
        width: 10,
      },
    ],
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  useSortBy)

  const firstPageRows = rows

  console.log('data',data)

  return (
    <div className="layoutWrapper">
      <div className="matchCenterWrapper">
        <div className="matchCenterHeader">
          <div className="simpleRow" style={{justifyContent:'space-between',paddingRight:50}}>
          <span className="manageApplicationName">Состав</span>

          {
            activeApplication
            ?
            <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Заявка</span>
            <SelectSearch
              options={applicationList ?applicationList :[]}
              search
              value={activeApplication}
              filterOptions={fuzzySearch}
              onChange={(x)=>changeLeague(x)}
              emptyMessage="Заявка не найдена"
              placeholder="Заявка"/>  
          </div>
          : null
          }

          </div>
        </div>

        {
          applications && applications.team_data
          ?
          <div>
          <div className="teamRostersHeaderTeamInfoWrapper">
          <div className="manageApplicationHeaderTeamInfoImageWrapper">
           {rendeTeamImage(applications.team_data.image)}   
          </div>
          <div className="manageApplicationHeaderTeamInfoName">
          {applications.team_data.name}
          </div>
        </div>
        <div style={{marginTop:60}}>
        <span className="manageApplicationName">
          {applicationList && applicationList.length ?applicationList.find(app => app.value == activeApplication).name + ' (' + applications.rosters.find(ros => ros.application_id == activeApplication).data.length + ')' :''}
        </span>
        </div>

        </div>
          :null
        }

        {
          applications && applications.team_data
          ?
          <Styles style={{marginTop:80}}>
          <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  },column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {firstPageRows.map(
              (row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                            height:120
                          },
                        })}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )}
            )}
          </tbody>
        </table>
        </Styles>

          :
                  <div className="loadingOverlay">
          <div className="loadingWrapper">
            <Loader
              type="Rings"
              color="#04a9f5"
              height={40}
              width={40}
              timeout={0}/>
            <span style={{marginLeft:10}}>Загрузка заявок</span>
          </div>
        </div>
        }

      </div>
    </div>
  );
}