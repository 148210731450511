import React, { useState, useEffect, useRef } from "react";
import "../styles/styles.css";
import {IoIosArrowForward} from "react-icons/io";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/im";
import { SegmentedControl } from 'segmented-control'
import Loader from "react-loader-spinner";
import {BASE_URL} from '../constants/config'
import soccerPlayerDefault from "../images/soccerPlayerDefault.png";
import defaultTeamLogo from "../images/defaultTeamLogo.png";
import defaultTeamLogoTwo from "../images/defaultTeamLogo2.png";
import defaultAvatar from "../images/defaultAvatar.png";
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';




function SearchPlayersSideBar({isOpen, closeWidget, searchPlayers, data, loading, addPlayerToBlackList, errorAdd, teamData, fetchRoster, clearRoster, roster, applicationList}) {
  const [searchType, setSearchType] = useState(1);
  const [name, setName] = useState('')
  const [family, setFamily] = useState('')
  const [error, setError] = useState('')
  const [chosenPlayers,setChosenPlayers] = useState([])
  const [step, setStep] = useState(1);
  const [blockReason, setBlockReason] = useState('')
  const [price, setPrice] = useState('')
  const [teamName, setTeamName] = useState('')
  const [activeApplication, setActiveApplication] = useState(false);
  const [chosenPlayersInTeams, setChosenPlayersInTeams] = useState([]);


  const makeSearch = () => {
    setError('')
    if(searchType == 1){
      if(family && family.length){
        searchPlayers(family,name,'player','')
      } else {
        setError('Введите фамилию')
      }
    } else {
      if(teamName && teamName.length){
        searchPlayers('','','team',teamName)
      } else {
        setError('Введите название команды')
      }
    }
  }

  const fuzzySearch = (options) => {
    const fuse = new Fuse(options, {
      keys: ['name'],
      threshold: 0.3,
  });

  return (value) => {
      if (!value.length) {
          return options;
      }
      return fuse.search(value).map(item => item.item);

  };
  }

  const choosePlayer = (player, type) => {
    if(type && !player){
      setChosenPlayers((state) => [...chosenPlayersInTeams])
      setStep(3)
    } else {
      setChosenPlayers((state) => [player])
      setStep(3)
    }

  }

  const num_word = (value, words) =>{  
    value = Math.abs(value) % 100; 
    var num = value % 10;
    if(value > 10 && value < 20) return words[2]; 
    if(num > 1 && num < 5) return words[1];
    if(num == 1) return words[0]; 
    return words[2];
  }

  const choosePlayerInTeam = (player) => {
    const chosenPlayersInTeamsTemp = chosenPlayersInTeams
    const index = chosenPlayersInTeamsTemp.findIndex(id => id == player.id)
    if(index == -1){
      setChosenPlayersInTeams((state) => [...state,player.id])
    } else {
      chosenPlayersInTeamsTemp.splice(index,1);
      setChosenPlayersInTeams((state) => [...chosenPlayersInTeamsTemp])
    }
  }

  const choosTeam = (team) => {
    fetchRoster(team.id)
    setStep(2)
  }

  const cancelInput = () => {
    setPrice('')
    setBlockReason('')
    setStep(1)
    setChosenPlayersInTeams([])
    setActiveApplication(false)
  }

  const addToBlackList = () => {
    if(blockReason && blockReason.length){
      if(chosenPlayers.length && chosenPlayers.length == 1){
        const data = {
          player_id: chosenPlayers[0].id,
          reason: blockReason ,
          price: price && price.length && parseInt(price) ?parseInt(price) :false
        }
        addPlayerToBlackList(data)
      } else if (chosenPlayers.length && chosenPlayers.length > 1){
        const data = {
          player_id: chosenPlayers,
          reason: blockReason ,
          price: price && price.length && parseInt(price) ?parseInt(price) :false
        }
        addPlayerToBlackList(data)
      }

    }
  }

  const renderSearchZone = () => {
    if(searchType == 1){
      return (
        <div className="blacklistAddPlayersPageSearchZoneContainer">
        <div className="blacklistAddPlayersPageSearchZoneWrapper">
          <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Фамилия</span>
              <input type="text" id="input-1" value={family} className="blacklistAddPlayersPageSearchZoneSearch" placeholder="Фамилия" onChange={(event) => setFamily(event.target.value)}/>
          </div>
          <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Имя</span>
              <input type="text" value={name} id="input-2" className="blacklistAddPlayersPageSearchZoneSearch" placeholder="Имя" onChange={(event) => setName(event.target.value)}/>
          </div>
          <div onClick={makeSearch} className="matchChangeResultsButton" style={{marginTop:19}}>
              <span className="matchChangeResultsButtonText">Поиск</span>
            </div>
        </div>
        </div>
      )
    } else {
      return (
        <div className="blacklistAddPlayersPageSearchZoneContainer">
        <div className="blacklistAddPlayersPageSearchZoneWrapper" style={{width:'55%'}}>
          <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Название</span>
              <input type="text" value={teamName} id="input-3" className="blacklistAddPlayersPageSearchZoneSearch" placeholder="Название" onChange={(event) => setTeamName(event.target.value)}/>
          </div>
          <div onClick={makeSearch} className="matchChangeResultsButton" style={{marginTop:19}}>
              <span className="matchChangeResultsButtonText">Поиск</span>
            </div>
        </div>
        </div>
      )
    }
  }

  const renderInputZone = () => {
    if(chosenPlayers && chosenPlayers.length){
      return (
        <div className="blacklistAddPlayersPageInputZoneContainer">
        <div className="blacklistAddPlayersPageInputZoneButtonsWrapper">
            <div onClick={cancelInput} className="matchChangeResultsButton" style={{marginTop:19,backgroundColor:'rgb(253, 54, 71)'}}>
              <span className="matchChangeResultsButtonText">Отмена</span>
            </div>
            <div onClick={addToBlackList} className="matchChangeResultsButton" style={{marginTop:19,backgroundColor: blockReason.length ?'rgba(4,169,245,1)' :'rgba(4,169,245,0.6)'}}>
              <span className="matchChangeResultsButtonText">Принять</span>
            </div>
        </div>
        {
          chosenPlayers.length == 1 && chosenPlayers[0].name
          ?
          <div 
          className="blacklistChosenPlayersPagePlayerWrapper">
          <div className="blacklistAddPlayersPagePlayerPhotoWrapper">
            <img src={BASE_URL.url + chosenPlayers[0].image} className="blacklistAddPlayersPagePlayerPhoto"/>  
          </div>
            <div className="blackListPlayerFamilyWrapper">
              <span className="blacklistPlayerFamilyText blacklistPlayerFamilyTextBold">{chosenPlayers[0].family}</span>
              <span className="blacklistPlayerFamilyText">{chosenPlayers[0].name}</span>
            </div>
            <div className="blacklistAddPlayersPagePlayerIconWrapper">
              <img src={soccerPlayerDefault} className="blacklistAddPlayersPagePlayerIcon"/>  
              </div>
            </div>
            :<span style={{marginTop:20}}>{chosenPlayers.length} {num_word(chosenPlayers.length, ['игрок', 'игрока', 'игроков'])}</span>
        }

            <div className="matchAdditionalInfoSelectHeadingWrapper" style={{width:'60%',marginTop:30}}>
            <span className="matchAdditionalInfoSelectHeading">Причина блокировки*</span>
              <textarea className="blacklistAddPlayersPageSearchZoneSearch" style={{height:70}} placeholder="Причина" onChange={(event) => setBlockReason(event.target.value)}/>
          </div>
          <div className="matchAdditionalInfoSelectHeadingWrapper" style={{width:'60%',marginTop:30}}>
            <span className="matchAdditionalInfoSelectHeading">Стоимость штрафа</span>
              <input 
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
                }} 
              type="text" 
              className="blacklistAddPlayersPageSearchZoneSearch" 
              placeholder="Стоимость" 
              onChange={(event) => setPrice(event.target.value)}/>
          </div>
          {renderError(errorAdd && errorAdd.data && errorAdd.data.length ?errorAdd.data :'')}

        </div>
      )
    } else {
      <div className="blacklistAddPlayersPageInputZoneContainer">
      <div className="blacklistAddPlayersPageInputZoneButtonsWrapper">
          <div onClick={makeSearch} className="matchChangeResultsButton" style={{marginTop:19,backgroundColor:'rgb(253, 54, 71)'}}>
            <span className="matchChangeResultsButtonText">Отмена</span>
          </div>
      </div>
      </div>
    }
  }

  const renderError = (error_input) => {
    if(error_input && error_input.length){
      return (
        <div className="blacklistAddPlayersPageErrorWrapper">
          <span className="blacklistAddPlayersPageErrorText">{error_input}</span>
        </div>
      )
    } else {
      return(
        <div className="blacklistAddPlayersPageErrorWrapper"/>
      )
    }
  }

  const renderTeamImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultLogo.png')){
      return (
        <img src={defaultTeamLogo} className="blacklistAddPlayersPageTeamPhoto"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="blacklistAddPlayersPageTeamPhoto"/>  
      )
    }
  }

  const renderPlayerImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultAvatar.png')){
      return (
        <img src={defaultAvatar} className="blacklistAddPlayersPagePlayerPhoto"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="blacklistAddPlayersPagePlayerPhoto"/>  
      )
    }
  }

  const renderContent = () => {
    if(loading){
      return (
        <div className="blacklistAddPlayersPageLoadingOverlay">
        <div className="loadingWrapper">
          <Loader
            type="Rings"
            color="#04a9f5"
            height={40}
            width={40}
            timeout={0}/>
          <span style={{marginLeft:10}}>Загрузка списка</span>
        </div>
      </div>
      )
    } else {
      if(searchType == 1){
        if(data && data.length){
          return (
            <div className="blacklistAddPlayersPageContentWrapper">
              {
                data.map((item,index) => (
                  <div 
                    key={'search_player' + index} 
                    className="blacklistAddPlayersPagePlayerWrapper" 
                    style={{backgroundColor: index % 2 == 0 ?'rgb(245,246,247)' :'transparent'}}
                    onClick={()=>choosePlayer(item)}>
                    <div className="blacklistAddPlayersPagePlayerPhotoWrapper">
                      {renderPlayerImage(item.image)}
                    </div>
                    <div className="blackListPlayerFamilyWrapper">
                      <span className="blacklistPlayerFamilyText blacklistPlayerFamilyTextBold">{item.family}</span>
                      <span className="blacklistPlayerFamilyText">{item.name} {item.father_name}</span>
                    </div>
                    <div className="blacklistAddPlayersPagePlayerIconWrapper">
                      <img src={soccerPlayerDefault} className="blacklistAddPlayersPagePlayerIcon"/>  
                    </div>
                  </div>
                ))
              }
            </div>
          )
        } else {
          return (
            <div className="blacklistAddPlayersPageEmptyWrapper">
              <span className="emptyText">Похоже игроков по заданному критерию нет...</span>
            </div>
          )
        }
  
      } else {
        if(teamData && teamData.length){
          return (
            <div className="blacklistAddPlayersPageContentWrapper">
              {
                teamData.map((item,index) => (
                  <div 
                    key={'search_team' + index} 
                    className="blacklistAddPlayersPagePlayerWrapper" 
                    style={{backgroundColor: index % 2 == 0 ?'rgb(245,246,247)' :'transparent'}}
                    onClick={()=>choosTeam(item)}>
                    <div className="blacklistAddPlayersPagePlayerPhotoWrapper">
                      {renderTeamImage(item.image)}
                    </div>
                    <div className="blackListPlayerFamilyWrapper">
                      <span className="blacklistPlayerFamilyText">{item.name}</span>
                    </div>
                    <div className="blacklistAddPlayersPagePlayerIconWrapper">
                      <img src={defaultTeamLogoTwo} className="blacklistAddPlayersPagePlayerIcon"/>  
                    </div>
                  </div>
                ))
              }
            </div>
          )
        } else {
          return (
            <div className="blacklistAddPlayersPageEmptyWrapper">
              <span className="emptyText">Похоже команд по заданному критерию нет...</span>
            </div>
          )
        }
  
      }
    } 


  }

  const renderRoster = () => {
   if(applicationList && applicationList.length && activeApplication && roster.rosters.find(ros => ros.application_id == activeApplication).data){
    return (
      <div style={{width:'80%'}}>
              {
                roster.rosters.find(ros => ros.application_id == activeApplication).data.map((item,index) => (
                  <div 
                    key={'search_player_in_team_' + index} 
                    className="blacklistAddPlayersPagePlayerWrapper" 
                    style={{backgroundColor: index % 2 == 0 ?'rgb(245,246,247)' :'transparent',width:'85%'}}
                    onClick={()=>choosePlayerInTeam(item)}>
                    <div className="blacklistAddPlayersPagePlayerPhotoWrapper">
                      {renderPlayerImage(item.image)}
                    </div>
                    <div className="blackListPlayerFamilyWrapper">
                      <span className="blacklistPlayerFamilyText blacklistPlayerFamilyTextBold">{item.family}</span>
                      <span className="blacklistPlayerFamilyText">{item.name} {item.father_name}</span>
                    </div>
                    {
                      chosenPlayersInTeams.findIndex(id => id == item.id) !== -1
                      ?<ImCheckboxChecked size="1.8em" color="#04a9f5"/>
                      :<ImCheckboxUnchecked size="1.8em" color="#04a9f5"/>

                      
                    }
                    
                  </div>
                ))
              }
      </div>

    )
   }
  }

  const changeLeague = (applic) => {
    //_loadAsyncApplications(onlyNew, league)
    setChosenPlayersInTeams([])
    setActiveApplication(applic)
  }

  if(step == 1){
    return (
      <div className="blacklistAddPlayersPageWrapper shadow" id="blacklistAddPlayersPageWrapper">
        <div className="blacklistAddPlayersCloseButton shadow" id="blacklistAddPlayersCloseButton" onClick={()=>closeWidget()}>
          <IoIosArrowForward size="1.8em" color="rgba(0,0,0,1)"/>
        </div>
        <div className="blacklistAddPlayersPageScrollZone">
        <div className="blacklistAddPlayersPageContainer">
          <div className="blacklistAddPlayersPageHeading">
            <span className="blacklistAddPlayersPageHeadingText">Поиск игроков</span>
          </div>
          <SegmentedControl
            name="oneDisabled"
            options={[
              { label: "Игроки", value: 1, default: true },
              { label: "Команды", value: 2 },
            ]}
            setValue={newValue => setSearchType(newValue)}
            style={{ width: 250, color: '#04a9f5',fontSize:13.5 }} // purple400
            />
        </div>
        {renderSearchZone()}
        {renderError(error)}
        {renderContent()}
        </div>
      </div>
  );
  } else if(step == 2){
    return (
      <div className="blacklistAddPlayersPageWrapper shadow" id="blacklistAddPlayersPageWrapper">
        <div className="blacklistAddPlayersCloseButton shadow" id="blacklistAddPlayersCloseButton" onClick={()=>closeWidget()}>
          <IoIosArrowForward size="1.8em" color="rgba(0,0,0,1)"/>
        </div>
        <div className="blacklistAddPlayersPageScrollZone">
        <div className="blacklistAddPlayersPageContainer">
          <div className="blacklistAddPlayersPageHeading">
            <span className="blacklistAddPlayersPageHeadingText">Поиск игроков</span>
          </div>
          <div style={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',display:'flex',marginTop:30}}>
          <div onClick={cancelInput} className="matchChangeResultsButton" style={{marginRight:30,backgroundColor:'rgb(253, 54, 71)'}}>
              <span className="matchChangeResultsButtonText">Отмена</span>
              </div>
          <SelectSearch
              options={applicationList ?applicationList :[]}
              search
              //value={activeApplication}
              filterOptions={fuzzySearch}
              onChange={(x)=>changeLeague(x)}
              emptyMessage="Заявка не найдена"
              placeholder="Заявка"/> 
              <div onClick={()=>choosePlayer(false,true)} className="matchChangeResultsButton" style={{marginLeft:30}}>
              <span className="matchChangeResultsButtonText">Выбрать</span>
              </div>
            </div>
            <span className="blacklistAddPlayersPageWrapperApplicationHeading">
        {applicationList && applicationList.length && activeApplication ?applicationList.find(app => app.value == activeApplication).name + ' (' + roster.rosters.find(ros => ros.application_id == activeApplication).data.length + ')' :''}

        </span>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
          {renderRoster()}
        </div>


        </div>
      </div>
  );
  } else {
    return (
      <div className="blacklistAddPlayersPageWrapper shadow" id="blacklistAddPlayersPageWrapper">
        <div className="blacklistAddPlayersCloseButton shadow" id="blacklistAddPlayersCloseButton" onClick={()=>closeWidget()}>
          <IoIosArrowForward size="1.8em" color="rgba(0,0,0,1)"/>
        </div>
        <div className="blacklistAddPlayersPageScrollZone">
        <div className="blacklistAddPlayersPageContainer">
          <div className="blacklistAddPlayersPageHeading">
            <span className="blacklistAddPlayersPageHeadingText">Добавить игрока в черный список</span>
          </div>
          <div className="blacklistAddPlayersPageInputZoneWrapper">
            {renderInputZone()}
          </div>
        </div>
        </div>
      </div>
  );
  }

}

export default SearchPlayersSideBar;
