import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding: 0,
    borderRadius:  '5vw'
  }
};

Modal.setAppElement('#root');

function AuthModal(props) {

  return (
    <Modal
    isOpen={props.visible}
    //onAfterOpen={afterOpenModal}
    onRequestClose={props.closeModal}
    style={customStyles}>
      <div className="authModal">
        <div className="authModalHeader">
          Вход для администраторов
        </div>
        <div className="authModalInputWrapper">
          <input 
            type="text" 
            className="authModalInput"
            value={props.userName} 
            placeholder="Логин"
            onChange={e => {props.setUserName(e.target.value)}}/>
        </div>  
        <div className="authModalInputWrapper">
          <input 
            type="password" 
            className="authModalInput"
            value={props.password} 
            placeholder="Пароль"
            onChange={e => {props.setPassword(e.target.value)}}/>
        </div>
        <div className="authModalButtonWrapper">
          <button onClick={props.login} className="authModalButton">
            <h2 className="authModalButtonText">Войти</h2>
          </button>
        </div>
        { props.isError &&
        <div className="authModalErrorTextWrapper">
          <div className="authModalErrorText">
            {props.errorText}
          </div>
        </div>
        }
      </div>

  </Modal>
  );
}

export default AuthModal;
