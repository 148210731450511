import React, { useState } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../styles/styles.css";
import "../styles/styles.scss";
import 'react-calendar/dist/Calendar.css';
import Admin from '../layouts/Admin';
import Main from '../layouts/Main';
import ManageMatchResult from '../layouts/ManageMatchResult';
import ManageApplication from '../layouts/ManageApplication';
import { AuthContext } from "../utilities/auth";
import PrivateRoute from '../components/PrivateRoute';
import TeamRosters from '../layouts/TeamRosters';
import BlackList from '../layouts/BlackList'
import ApplicationsManagment from '../layouts/ApplicationsManagment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App(props) {
  const existingTokens = JSON.parse(localStorage.getItem("userData"));
  const [userData, setUserData] = useState(existingTokens);

  const setData = (data) => {
    localStorage.setItem("userData", JSON.stringify(data ?data :false));
    setUserData(data);
  }

  return (
    <AuthContext.Provider value={{ userData, setUserData: setData }}>
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <PrivateRoute path="/admin" component={Admin} />
          <PrivateRoute path="/applications_managment" component={ApplicationsManagment} />
          <PrivateRoute path="/black_list" component={BlackList} />
          <PrivateRoute path="/manage_match/:id" component={ManageMatchResult} />
          <PrivateRoute path="/manage_application/:id" component={ManageApplication} />
          <PrivateRoute path="/team_rosters/:id/:application_id" component={TeamRosters} />
          </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;