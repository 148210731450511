import React from "react";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";

function BottomModal(props) {

 const renderContent = () => {
    if(props && props.type && props.type === 'success'){
      return (
        <div className="bottomModalContent">
        <div className="bottomModalRow">
          <FiCheckCircle size="1.5em" color="#5CB65F"/>
          <span className="bottomModalHeading">Успешно</span>
        </div>
        <div className="bottomModalRowLarge">
          <span className="bottomModalText">{props.text}</span>
        </div>
      </div>
      )

    } else {
      return (
        <div className="bottomModalContent">
          <div className="bottomModalRow">
            <FiAlertCircle size="1.5em" color="#F45448"/>
            <span className="bottomModalHeading">Ошибка</span>
          </div>
          <div className="bottomModalRowLarge">
            <span className="bottomModalText">{props.text}</span>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={`bottomModalWrapper ${props.type === 'success' ?'bottomModalWrapperSuccess' :'bottomModalWrapperError'}`}>
      {renderContent()}
    </div> 
  );
}

export default BottomModal;