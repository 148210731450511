export const FETCH_ALL_MATCHES_BEGIN = 'FETCH_ALL_MATCHES_BEGIN';
export const FETCH_ALL_MATCHES_SUCCESS = 'FETCH_ALL_MATCHES_SUCCESS';
export const FETCH_ALL_MATCHES_FAILURE = 'FETCH_ALL_MATCHES_FAILURE';

export const FETCH_AVAILABLE_TEAMS_APPLICATIONS_BEGIN = 'FETCH_AVAILABLE_TEAMS_APPLICATIONS_BEGIN';
export const FETCH_AVAILABLE_TEAMS_APPLICATIONS_SUCCESS = 'FETCH_AVAILABLE_TEAMS_APPLICATIONS_SUCCESS';
export const FETCH_AVAILABLE_TEAMS_APPLICATIONS_FAILURE = 'FETCH_AVAILABLE_TEAMS_APPLICATIONS_FAILURE';

export const FETCH_TEAM_APPLICATION_QUERY_BEGIN = 'FETCH_TEAM_APPLICATION_QUERY_BEGIN';
export const FETCH_TEAM_APPLICATION_QUERY_SUCCESS = 'FETCH_TEAM_APPLICATION_QUERY_SUCCESS';
export const FETCH_TEAM_APPLICATION_QUERY_FAILURE = 'FETCH_TEAM_APPLICATION_QUERY_FAILURE';

export const FETCH_PLAYER_APPLICATIONS_BEGIN = 'FETCH_PLAYER_APPLICATIONS_BEGIN';
export const FETCH_PLAYER_APPLICATIONS_SUCCESS = 'FETCH_PLAYER_APPLICATIONS_SUCCESS';
export const FETCH_PLAYER_APPLICATIONS_FAILURE = 'FETCH_PLAYER_APPLICATIONS_FAILURE';


export const FETCH_ALL_ADMIN_LEAGUES_BEGIN = 'FETCH_ALL_ADMIN_LEAGUES_BEGIN';
export const FETCH_ALL_ADMIN_LEAGUES_SUCCESS = 'FETCH_ALL_ADMIN_LEAGUES_SUCCESS';
export const FETCH_ALL_ADMIN_LEAGUES_FAILURE = 'FETCH_ALL_ADMIN_LEAGUES_FAILURE';

export const FETCH_ROSTER_BEGIN = 'FETCH_ROSTER_BEGIN';
export const FETCH_ROSTER_SUCCESS = 'FETCH_ROSTER_SUCCESS';
export const FETCH_ROSTER_FAILURE = 'FETCH_ROSTER_FAILURE';


export const DECLINE_APPLICATION_BEGIN = 'DECLINE_APPLICATION_BEGIN';
export const DECLINE_APPLICATION_SUCCESS = 'DECLINE_APPLICATION_SUCCESS';
export const DECLINE_APPLICATION_FAILURE = 'DECLINE_APPLICATION_FAILURE';

export const ACCEPT_APPLICATION_BEGIN = 'ACCEPT_APPLICATION_BEGIN';
export const ACCEPT_APPLICATION_SUCCESS = 'ACCEPT_APPLICATION_SUCCESS';
export const ACCEPT_APPLICATION_FAILURE = 'ACCEPT_APPLICATION_FAILURE';

export const LOGIN_BEGIN   = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const REFRESH_TOKENS_BEGIN   = 'REFRESH_TOKENS_BEGIN';
export const REFRESH_TOKENS_SUCCESS   = 'REFRESH_TOKENS_SUCCESS';
export const REFRESH_TOKENS_FAILURE  = 'REFRESH_TOKENS_FAILURE';
export const INVALID_TOKEN = 'INVALID_TOKEN';

export const TRY_REMOVE_REFRESH_TOKEN = 'TRY_REMOVE_REFRESH_TOKEN';

export const FETCH_MATCH_BEGIN = 'FETCH_MATCH_BEGIN';
export const FETCH_MATCH_SUCCESS = 'FETCH_MATCH_SUCCESS';
export const FETCH_MATCH_FAILURE = 'FETCH_MATCH_FAILURE';

export const FETCH_MATCH_SET_APPLICATION_BEGIN = 'FETCH_MATCH_SET_APPLICATION_BEGIN';
export const FETCH_MATCH_SET_APPLICATION_SUCCESS = 'FETCH_MATCH_SET_APPLICATION_SUCCESS';
export const FETCH_MATCH_SET_APPLICATION_FAILURE = 'FETCH_MATCH_SET_APPLICATION_FAILURE';

export const FETCH_MATCH_ACTIVE_APPLICATION_BEGIN = 'FETCH_MATCH_ACTIVE_APPLICATION_BEGIN';
export const FETCH_MATCH_ACTIVE_APPLICATION_SUCCESS = 'FETCH_MATCH_ACTIVE_APPLICATION_SUCCESS';
export const FETCH_MATCH_ACTIVE_APPLICATION_FAILURE = 'FETCH_MATCH_ACTIVE_APPLICATION_FAILURE';

export const SET_MATCH_ALL_RESULTS_BEGIN = 'SET_MATCH_ALL_RESULTS_BEGIN';
export const SET_MATCH_ALL_RESULTS_SUCCESS = 'SET_MATCH_ALL_RESULTS_SUCCESS';
export const SET_MATCH_ALL_RESULTS_FAILURE = 'SET_MATCH_ALL_RESULTS_FAILURE';

export const SET_MATCH_EXTRA_DATA_BEGIN = 'SET_MATCH_EXTRA_DATA_BEGIN';
export const SET_MATCH_EXTRA_DATA_SUCCESS = 'SET_MATCH_EXTRA_DATA_SUCCESS';
export const SET_MATCH_EXTRA_DATA_FAILURE = 'SET_MATCH_EXTRA_DATA_FAILURE';

export const GET_ALL_STADIUMS_BEGIN = 'GET_ALL_STADIUMS_BEGIN';
export const GET_ALL_STADIUMS_SUCCESS = 'GET_ALL_STADIUMS_SUCCESS';
export const GET_ALL_STADIUMS_FAILURE = 'GET_ALL_STADIUMS_FAILURE';

export const GET_ALL_REFEREES_BEGIN = 'GET_ALL_REFEREES_BEGIN';
export const GET_ALL_REFEREES_SUCCESS = 'GET_ALL_REFEREES_SUCCESS';
export const GET_ALL_REFEREES_FAILURE = 'GET_ALL_REFEREES_FAILURE';

export const FETCH_BLACK_LIST_BEGIN = 'FETCH_BLACK_LIST_BEGIN';
export const FETCH_BLACK_LIST_SUCCESS = 'FETCH_BLACK_LIST_SUCCESS';
export const FETCH_BLACK_LIST_FAILURE = 'FETCH_BLACK_LIST_FAILURE';

export const FETCH_BLACK_LIST_PLAYER_BEGIN = 'FETCH_BLACK_LIST_PLAYER_BEGIN';
export const FETCH_BLACK_LIST_PLAYER_SUCCESS = 'FETCH_BLACK_LIST_PLAYER_SUCCESS';
export const FETCH_BLACK_LIST_PLAYER_FAILURE = 'FETCH_BLACK_LIST_PLAYER_FAILURE';

export const ADD_TO_BLACK_LIST_BEGIN = 'ADD_TO_BLACK_LIST_BEGIN';
export const ADD_TO_BLACK_LIST_SUCCESS = 'ADD_TO_BLACK_LIST_SUCCESS';
export const ADD_TO_BLACK_LIST_FAILURE = 'ADD_TO_BLACK_LIST_FAILURE';

export const DELETE_FROM_BLACK_LIST_BEGIN = 'DELETE_FROM_BLACK_LIST_BEGIN';
export const DELETE_FROM_BLACK_LIST_SUCCESS = 'DELETE_FROM_BLACK_LIST_SUCCESS';
export const DELETE_FROM_BLACK_LIST_FAILURE = 'DELETE_FROM_BLACK_LIST_FAILURE';

export const CHANGE_BLACK_LIST_BEGIN = 'CHANGE_BLACK_LIST_BEGIN';
export const CHANGE_BLACK_LIST_SUCCESS = 'CHANGE_BLACK_LIST_SUCCESS';
export const CHANGE_BLACK_LIST_FAILURE = 'CHANGE_BLACK_LIST_FAILURE';

export const FETCH_ADMIN_TEAMS_BEGIN = 'FETCH_ADMIN_TEAMS_BEGIN';
export const FETCH_ADMIN_TEAMS_SUCCESS = 'FETCH_ADMIN_TEAMS_SUCCESS';
export const FETCH_ADMIN_TEAMS_FAILURE = 'FETCH_ADMIN_TEAMS_FAILURE';


export const SEARCH_PLAYERS_BY_PARAMS_BEGIN = 'SEARCH_PLAYERS_BY_PARAMS_BEGIN';
export const SEARCH_PLAYERS_BY_PARAMS_SUCCESS = 'SEARCH_PLAYERS_BY_PARAMS_SUCCESS';
export const SEARCH_PLAYERS_BY_PARAMS_FAILURE = 'SEARCH_PLAYERS_BY_PARAMS_FAILURE';

export const SET_MATCH_BEST_PLAYER_BEGIN = 'SET_MATCH_BEST_PLAYER_BEGIN';
export const SET_MATCH_BEST_PLAYER_SUCCESS = 'SET_MATCH_BEST_PLAYER_SUCCESS';
export const SET_MATCH_BEST_PLAYER_FAILURE = 'SET_MATCH_BEST_PLAYER_FAILURE';

export const UNSET_MATCH_BEST_PLAYER_BEGIN = 'UNSET_MATCH_BEST_PLAYER_BEGIN';
export const UNSET_MATCH_BEST_PLAYER_SUCCESS = 'UNSET_MATCH_BEST_PLAYER_SUCCESS';
export const UNSET_MATCH_BEST_PLAYER_FAILURE = 'UNSET_MATCH_BEST_PLAYER_FAILURE';

