import React, { useState, useEffect } from "react";
import { FiCalendar } from "react-icons/fi";
import CustomCalendar from './CustomCalendar'
import moment from 'moment'
import useComponentVisible from '../utilities/useComponentVisible';
import Loader from "react-loader-spinner";
import { BASE_URL } from "../constants/config";
import { useDispatch, useSelector } from "react-redux";
import {fetchAllMatches} from '../actions/matches'
import {API_URLS} from '../constants/config'
import defaultTeamLogo from "../images/defaultTeamLogo.png";

export default function MatchCenter(props) {
  const [currentDate, setcurrentDate] = useState(moment(new Date()));
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);
  const matches = useSelector(state => state.matches.matchesData.data);
  const loading = useSelector(state => state.matches.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    _loadAsyncMatches(currentDate)
  }, []);

  const getDayNumber = () => {
    return currentDate.format('D')
  }

  const checkDateActive = (date) => {

    if(currentDate.isSame(date, 'day')){
      return 'matcheCenterMenuItemActive'
    } else {
      return ''
    }
  }

  const _loadAsyncMatches = (date) => {
    let url = API_URLS.getAllMatches;
    dispatch(fetchAllMatches(url,date.format('YYYY-MM-DD'),'currentDate',20,0))
  }

  const rendeTeamImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultLogo.png')){
      return (
        <img src={defaultTeamLogo} className="teamImage"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="teamImage"/>  
      )
    }
  }

  const checkCalendarSwitch = () => {
    if(checkDateActive(moment().subtract(1, 'days')) === '' 
    && checkDateActive(moment()) === '' && checkDateActive(moment().add(1, 'days')) === '' ){
      return 'matcheCenterCalendarActive';
    } else {
      return '';
    }
  }

  const openCalendar = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  const changeDate = (date) => {
    setIsComponentVisible(false)
    setcurrentDate(date)
    _loadAsyncMatches(date)
  }

  const getMatchState = (data) => {
    if(data.resheduled){
      return 'Матч перенесен';
    } else {
      if (data.status) {
        if(data.status == 5){
          return 'ТП';
        }else if(data.status == 3){
          return 'Перерыв';
        }else if(data.status == 2) {
          return 'Матч окончен';
        }else if (data.status === 0 && !data.second_time_start) {
          return 'Матч не начался';
        } else if (data.status === 0 && data.second_time_start) {
          return 'Перерыв';
        } else if (!data.second_time_start) {
          if(data.status == 1){
            return 'Идет 1-ый тайм';
          } else if(data.status == 4){
            return 'Доп. время. Идет 1-ый тайм';
          }
        } else if (data.second_time_start) {
          if(data.status == 1){
            return 'Идет 2-ый тайм';
          } else if(data.status == 4){
            return 'Доп. время. Идет 2-ый тайм';
          }
        } else {
          return 'Матч окончен';
        }
      } else {
        if (!data.second_time_start) {
          return 'Матч не начался';
        } else {
          return 'Перерыв';
        }
      }
    }

  };

  const renderMatchScore = (item) => {
    if(item.status){
      if(item.status === 0 && !item.second_time_start){
        return (
          <div className="matchResult">
          -:- 
       </div>
        )
      } else {
        return (
          <div className="matchResult">
          {item.home_team_scores}:{item.guest_team_scores}
        </div>
        )
      }
    } else {
      if (!item.second_time_start) {
        return (
          <div className="matchResult">
          -:- 
       </div>
        )
      } else {
        return (
          <div className="matchResult">
          {item.home_team_scores}:{item.guest_team_scores}
        </div>
        )
      }
    }

  }



  const Calendar = React.forwardRef((props, ref) => {
    return <div className={`matchCenterCalendarWrapper`} ref={ref}><CustomCalendar {...props} currentDate={currentDate.toDate()} 
    changeDate={(e) => changeDate(moment(e))}/></div>
  })

  const renderMatches = () => {
    return matches.map((item, index) => {
      console.log('item',item)
      return (
        <tr onClick={()=>props.goToMatchResult(item.id)} className="matchCenterRow" key={index}>
        <td className="matchTableTime">{item.time ?item.time.substring(item.time.length - 3 ,0) :'—'}</td>
        <td className="matchTableMatch">
          <div className="oneMatchTeamsWrapper">
            <div className="teamWrapper">
              <div className="teamName">{item.home_team_name}</div>
              {rendeTeamImage(item.home_team_image)}   
            </div>
              {renderMatchScore(item)}
            <div className="teamWrapper" style={{justifyContent:'flex-start'}}>
              {rendeTeamImage(item.guest_team_image)}   
              <div className="teamName">{item.guest_team_name}</div>  
            </div>
          </div>
        </td>
        <td className="matchTableStadium">{item.stadium_name}</td>
        <td className="matchTableStatus">{getMatchState(item)}</td>
        </tr>
      )
    })
  }

  const renderContent = () => {
    if(!loading){
      if(matches && matches.length > 0){
        return (
          <div className="tableWrapper">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="matchTableTime">Время</th>
                    <th className="matchTableMatch">Матч</th>
                    <th className="matchTableStadium">Стадион</th>
                    <th className="matchTableStatus">Статус</th>
                  </tr>
                </thead>
                <tbody>
                  {renderMatches()}
                </tbody>
              </table>
            </div>
          </div>
        )
      } else {
        return (
          <div className="emptyWrapper">
            <span className="emptyText">Похоже в это день матчей нет...</span>
          </div>
        )
      }
    } else {

      return (
        <div className="loadingOverlay">
          <div className="loadingWrapper">
            <Loader
              type="Rings"
              color="#04a9f5"
              height={40}
              width={40}
              timeout={0}/>
            <span style={{marginLeft:10}}>Загрузка матчей</span>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="matchCenterWrapper">
      <div className="matchCenterHeader">
        <span className="matchCenterName">Матч-Центр</span>
        <div className="dateWrapper">
          <div className={`calendarIconWrapper ${checkCalendarSwitch()}`} onClick={openCalendar}>
            {/* <img src={calendar} className="calendarIcon"/> */}
            <FiCalendar size="30px"/>
            <span className="dateNumber">{getDayNumber()}</span>
          </div>
          <span onClick={()=>changeDate(moment().subtract(1, 'days'))} className={`matcheCenterMenuItem ${checkDateActive(moment().subtract(1, 'days'))}`}>Вчера</span>
          <span onClick={()=>changeDate(moment())} className={`matcheCenterMenuItem ${checkDateActive(moment())}`}>Сегодня</span>
          <span onClick={()=>changeDate(moment().add(1, 'days'))} className={`matcheCenterMenuItem ${checkDateActive(moment().add(1, 'days'))}`}>Завтра</span>
        </div>
      </div>
      {
        isComponentVisible && 
        <Calendar 
          ref={ref} />
      }
      {renderContent()}
    </div>
  );
}
