import { FETCH_BLACK_LIST_BEGIN, FETCH_BLACK_LIST_SUCCESS , FETCH_BLACK_LIST_FAILURE,
    FETCH_BLACK_LIST_PLAYER_BEGIN, FETCH_BLACK_LIST_PLAYER_SUCCESS, FETCH_BLACK_LIST_PLAYER_FAILURE,
    ADD_TO_BLACK_LIST_BEGIN, ADD_TO_BLACK_LIST_SUCCESS, ADD_TO_BLACK_LIST_FAILURE,
    DELETE_FROM_BLACK_LIST_BEGIN, DELETE_FROM_BLACK_LIST_SUCCESS, DELETE_FROM_BLACK_LIST_FAILURE,
    CHANGE_BLACK_LIST_BEGIN, CHANGE_BLACK_LIST_SUCCESS, CHANGE_BLACK_LIST_FAILURE} from '../constants/action-types';
  import {BASE_URL} from '../constants/config'
  import {refreshTokens, logOut} from './usersActions'
  import {checkIfTokenExpired, getTokensFromStore } from '../utilities/tokens'
  import axios from 'axios';
  let token;

  export const fetchBlackListBegin = () => ({
    type: FETCH_BLACK_LIST_BEGIN
  });
  
  export const fetchBlackListSuccess = blacklist => ({
    type: FETCH_BLACK_LIST_SUCCESS,
    payload: { blacklist }
  });
  
  export const fetchBlackListFailure = error => ({
    type: FETCH_BLACK_LIST_FAILURE,
    payload: { error }
  });

  export const fetchBlackListPlayerBegin = () => ({
    type: FETCH_BLACK_LIST_PLAYER_BEGIN
  });
  
  export const fetchBlackListPlayerSuccess = blacklist_player => ({
    type: FETCH_BLACK_LIST_PLAYER_SUCCESS,
    payload: { blacklist_player }
  });
  
  export const fetchBlackListPlayerFailure = error => ({
    type: FETCH_BLACK_LIST_PLAYER_FAILURE,
    payload: { error }
  });

  export const addToBlackListBegin = () => ({
    type: ADD_TO_BLACK_LIST_BEGIN
  });
  
  export const addToBlackListSuccess = blacklist_player => ({
    type: ADD_TO_BLACK_LIST_SUCCESS,
    payload: { blacklist_player }
  });
  
  export const addToBlackListFailure = error => ({
    type: ADD_TO_BLACK_LIST_FAILURE,
    payload: { error }
  });

  export const changeBlackListBegin = () => ({
    type: CHANGE_BLACK_LIST_BEGIN
  });
  
  export const changeBlackListSuccess = blacklist_player => ({
    type: CHANGE_BLACK_LIST_SUCCESS,
    payload: { blacklist_player }
  });
  
  export const changeBlackListFailure = error => ({
    type: CHANGE_BLACK_LIST_FAILURE,
    payload: { error }
  });

  export const deleteFromBlackListBegin = () => ({
    type: DELETE_FROM_BLACK_LIST_BEGIN
  });
  
  export const deleteFromBlackListSuccess = blacklist_player => ({
    type: DELETE_FROM_BLACK_LIST_SUCCESS,
    payload: { blacklist_player }
  });
  
  export const deleteFromBlackListFailure = error => ({
    type: DELETE_FROM_BLACK_LIST_FAILURE,
    payload: { error }
  });

  export function deleteFromBlackList(url, data) {
    return async dispatch => {
      const isTokenExpired = checkIfTokenExpired();
      if(isTokenExpired){
        await dispatch(refreshTokens())
      }
      const tokens = getTokensFromStore();
      dispatch(deleteFromBlackListBegin());
      return axios.post(BASE_URL.url + url,{
          ...data
          },
          {
            headers: {
              "Authorization" : "Bearer " + tokens.access_token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }).then(res => {
        dispatch(deleteFromBlackListSuccess(res));
        return res;
      })
          .catch((error) => {
            dispatch(deleteFromBlackListFailure(error.response))
            if(error.response.status == 401) {
              dispatch(logOut())
            }
          });
    };
  }
  

  export function changeBlackList(url, data) {
    return async dispatch => {
      const isTokenExpired = checkIfTokenExpired();
      if(isTokenExpired){
        await dispatch(refreshTokens())
      }
      const tokens = getTokensFromStore();
      dispatch(changeBlackListBegin());
      return axios.post(BASE_URL.url + url,{
          ...data
          },
          {
            headers: {
              "Authorization" : "Bearer " + tokens.access_token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }).then(res => {
        dispatch(changeBlackListSuccess(res));
        return res;
      })
          .catch((error) => {
            dispatch(changeBlackListFailure(error.response))
            if(error.response.status == 401) {
              dispatch(logOut())
            }
          });
    };
  }

  export function addToBlackList(url, data) {
    return async dispatch => {
      const isTokenExpired = checkIfTokenExpired();
      if(isTokenExpired){
        await dispatch(refreshTokens())
      }
      const tokens = getTokensFromStore();
      dispatch(addToBlackListBegin());
      return axios.post(BASE_URL.url + url,{
          ...data
          },
          {
            headers: {
              "Authorization" : "Bearer " + tokens.access_token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }).then(res => {
        dispatch(addToBlackListSuccess(res));
        return res;
      })
          .catch((error) => {
            dispatch(addToBlackListFailure(error.response))
            if(error.response.status == 401) {
              dispatch(logOut())
            }
          });
    };
  }

  export function fetchBlackListPlayer(url, data) {
    return async dispatch => {
      const isTokenExpired = checkIfTokenExpired();
      if(isTokenExpired){
        await dispatch(refreshTokens())
      }
      const tokens = getTokensFromStore();
      dispatch(fetchBlackListPlayerBegin());
      return axios.post(BASE_URL.url + url,{
          ...data
          },
          {
            headers: {
              "Authorization" : "Bearer " + tokens.access_token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          }).then(res => {
        dispatch(fetchBlackListPlayerSuccess(res));
        return res;
      })
          .catch((error) => {
            dispatch(fetchBlackListPlayerFailure(error.response))
            if(error.response.status == 401) {
              dispatch(logOut())
            }
          });
    };
  }
  
  export function fetchBlackList(url, data) {
    return async dispatch => {
      if(token){
        // Cancel the previous request before making a new request
        token.cancel()
      }
      token = axios.CancelToken.source()
      const isTokenExpired = checkIfTokenExpired();
      if(isTokenExpired){
        await dispatch(refreshTokens())
      }
      const tokens = getTokensFromStore();
      dispatch(fetchBlackListBegin());
      return axios.post(BASE_URL.url + url,{
          ...data
          },
          {
            headers: {
              "Authorization" : "Bearer " + tokens.access_token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            cancelToken: token.token
          }).then(res => {
        dispatch(fetchBlackListSuccess(res));
        return res;
      })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log('post Request canceled');
            } else {
            dispatch(fetchBlackListFailure(error.response))
            if(error.response.status == 401) {
              dispatch(logOut())
            }
          }
          });
    };
  }