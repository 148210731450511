import { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_FAILURE,
  LOGIN_GUEST, TRY_REMOVE_REFRESH_TOKEN,
  REFRESH_TOKENS_BEGIN,REFRESH_TOKENS_SUCCESS,REFRESH_TOKENS_FAILURE,
  REGISTER_BEGIN,REGISTER_SUCCESS,REGISTER_FAILURE,
  UPDATE_USER_DATA_BEGIN, UPDATE_USER_DATA_SUCCESS, UPDATE_USER_DATA_FAILURE,
  SET_FCM_TOKEN_BEGIN, SET_FCM_TOKEN_SUCCESS, SET_FCM_TOKEN_FAILURE} from '../constants/action-types';
const initialState = {
  userData: {},
  loading: false,
  error: null
};

export default function userReducer(state = initialState, action) {
  switch(action.type) {

    case LOGIN_BEGIN:

      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case LOGIN_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        userData: action.payload.userData
      };

    case REFRESH_TOKENS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case REFRESH_TOKENS_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: {...state.userData, data:{
          ...state.userData.data,
          access_token: action.payload.accessToken.data.access_token,
          access_token_expire: action.payload.accessToken.data.access_token_expire,
          refresh_token: action.payload.accessToken.data.refresh_token}}
      };

    case REFRESH_TOKENS_FAILURE:
      return {
        ...state,
        loading: false,
        userData: {...state.userData, data:{...state.userData.data,access_token: false, refresh_token: false }}
      };

    case TRY_REMOVE_REFRESH_TOKEN:
      return {
        ...state,
      };



    case LOGIN_FAILURE:

      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        userData: {}
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}