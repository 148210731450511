import { FaSearch } from "react-icons/fa";


const SearchBar = ({onChange}) => (
<div className="searchContainer">
   <div className="searchWrapper">
      <input type="text" className="searchTerm" placeholder="Поиск по фамилии" onChange={(event) => onChange(event.target.value)}/>
      <button type="submit" className="searchButton">
      <FaSearch size="0.8em" color="white"/>
     </button>
   </div>
</div>
);

export default SearchBar;