import { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_FAILURE,
  LOGIN_GUEST, LOG_OUT_SUCCESS, TRY_REMOVE_REFRESH_TOKEN,
  REFRESH_TOKENS_BEGIN,REFRESH_TOKENS_SUCCESS,REFRESH_TOKENS_FAILURE,} from '../constants/action-types';
import {BASE_URL,API_URLS} from '../constants/config'
import {getExpiredDate, getTokensFromStore, checkIfTokenExpired} from '../utilities/tokens'
import axios from 'axios';



export const loginBegin = () => ({
  type: LOGIN_BEGIN
});

export const loginSuccess = userData => ({
  type: LOGIN_SUCCESS,
  payload: { userData }
});


export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: { error }
});

export const tryRemoveRefreshToken = () => ({
  type: TRY_REMOVE_REFRESH_TOKEN,
});

export const logOutSuccess = () => ({
  type: LOG_OUT_SUCCESS,
});

export const refreshTokensBegin = () => ({
  type: REFRESH_TOKENS_BEGIN
});

export const refreshTokensSuccess = accessToken => ({
  type: REFRESH_TOKENS_SUCCESS,
  payload: { accessToken }
});

export const refreshTokensFailure = error => ({
  type: REFRESH_TOKENS_FAILURE,
  payload: { error }
});

export function refreshTokens() {
  return dispatch => {
    dispatch(refreshTokensBegin());
    const tokens = getTokensFromStore();
    return axios.post(BASE_URL.url + API_URLS.refreshTokens,{
      refresh_token:tokens.refresh_token,
    },{
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + tokens.access_token
      }
    }).then(res => {
      const expiredData = getExpiredDate(res.data);
      res.data.access_token_expire = expiredData;
      dispatch(refreshTokensSuccess(res));
      return res;
    }).catch((error) => {
      if (error.response && error.response.status) {
        dispatch(refreshTokensFailure(error.response))
        if(error.response.status == 401) {
          dispatch(logOut())
        }
      }
        });
  };
}

export function login(url, login, password) {
  return dispatch => {
    dispatch(loginBegin());
    return axios.post(BASE_URL.url + url,{
      login: login,
      password: password,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    }).then(res => {
      const expiredData = getExpiredDate(res.data);
      res.data.access_token_expire = expiredData;
      dispatch(loginSuccess(res));
      return res;
        })
        .catch((error) => {
          dispatch(loginFailure(error.response))
          return error.response;
    });
  };
}

export function removeRefreshToken(url) {
  return async dispatch => {
    const isTokenExpired = checkIfTokenExpired();
    if(isTokenExpired){
      await dispatch(refreshTokens())
    }
    const tokens = getTokensFromStore();
    return axios.get(BASE_URL.url + url,
        {headers: {
          "Authorization" : "Bearer " + tokens.access_token
        }
        }).then(res => {
      dispatch(tryRemoveRefreshToken());
      return res;
    })
        .catch((error) => {
        });
  };
}

export function logOut() {
  return dispatch => {
    dispatch(logOutSuccess());
    return 'success';
  };
}
