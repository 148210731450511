import {
  SEARCH_PLAYERS_BY_PARAMS_BEGIN, SEARCH_PLAYERS_BY_PARAMS_SUCCESS, SEARCH_PLAYERS_BY_PARAMS_FAILURE} from '../constants/action-types';

import {BASE_URL} from '../constants/config'
import axios from 'axios';
import {refreshTokens, logOut} from './usersActions'
import {checkIfTokenExpired, getTokensFromStore } from '../utilities/tokens'
let token;

export const searchPlayersBegin = () => ({
  type: SEARCH_PLAYERS_BY_PARAMS_BEGIN
});

export const searchPlayersSuccess = players => ({
  type: SEARCH_PLAYERS_BY_PARAMS_SUCCESS,
  payload: { players }
});

export const searchPlayersFailure = error => ({
  type: SEARCH_PLAYERS_BY_PARAMS_FAILURE,
  payload: { error }
});


export function searchPlayers(url, params, offset, limit) {
  return dispatch => {
    dispatch(searchPlayersBegin());
    return axios.post(BASE_URL.url + url,{
      name: params.name,
      family: params.family,
      father_name: params.father_name,
      offset,
      limit
    }, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(res => {
      dispatch(searchPlayersSuccess(res));
      return res;
    }).catch((error) => {
      dispatch(searchPlayersFailure(error.response))
    });
  };
}