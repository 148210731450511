import { FETCH_ALL_MATCHES_BEGIN, FETCH_ALL_MATCHES_SUCCESS, FETCH_ALL_MATCHES_FAILURE,
  FETCH_MATCH_BEGIN, FETCH_MATCH_SUCCESS, FETCH_MATCH_FAILURE,
  FETCH_MATCH_ACTIVE_APPLICATION_BEGIN, FETCH_MATCH_ACTIVE_APPLICATION_SUCCESS, FETCH_MATCH_ACTIVE_APPLICATION_FAILURE,
  SET_MATCH_ALL_RESULTS_BEGIN, SET_MATCH_ALL_RESULTS_SUCCESS, SET_MATCH_ALL_RESULTS_FAILURE,
  SET_MATCH_EXTRA_DATA_BEGIN, SET_MATCH_EXTRA_DATA_SUCCESS, SET_MATCH_EXTRA_DATA_FAILURE,
  SET_MATCH_BEST_PLAYER_BEGIN, SET_MATCH_BEST_PLAYER_SUCCESS, SET_MATCH_BEST_PLAYER_FAILURE,
  UNSET_MATCH_BEST_PLAYER_BEGIN, UNSET_MATCH_BEST_PLAYER_SUCCESS, UNSET_MATCH_BEST_PLAYER_FAILURE

} from '../constants/action-types';


const initialState = {
  matchData: {},
  matchesData: {},
  matchActiveApplication:[],
  bestPlayers:{},
  loading: false,
  loadingApplication: false,
  error: null
};

function matchesReducer(state = initialState, action) {
  switch(action.type) {
    case SET_MATCH_BEST_PLAYER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case SET_MATCH_BEST_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        bestPlayers: action.payload.players
      };

    case SET_MATCH_BEST_PLAYER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UNSET_MATCH_BEST_PLAYER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case UNSET_MATCH_BEST_PLAYER_SUCCESS:
      return {
        ...state,
        loading: false,
        bestPlayers: action.payload.players
      };

    case UNSET_MATCH_BEST_PLAYER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SET_MATCH_EXTRA_DATA_BEGIN:
      return {
        ...state,
        loadingApplication: true,
        error: null
      };

    case SET_MATCH_EXTRA_DATA_SUCCESS:
      return {
        ...state,
        loadingApplication: false,
        matchData: action.payload.matchData
      };

    case SET_MATCH_EXTRA_DATA_FAILURE:
      return {
        ...state,
        loadingApplication: false,
        error: action.payload.error,
        matchesData: {}
      };

    case SET_MATCH_ALL_RESULTS_BEGIN:
      return {
        ...state,
        loadingApplication: true,
        error: null
      };

    case SET_MATCH_ALL_RESULTS_SUCCESS:
      let matchesData = state.matchesData;
      if(state.matchesData && state.matchesData.data && state.matchesData.data.length && state.matchesData.data.length > 0 && 
        action.payload.matchData.data && action.payload.matchData.data.id && state.matchesData.data.findIndex(match => match.id == action.payload.matchData.data.id) != -1){
          const index = state.matchesData.data.findIndex(match => match.id == action.payload.matchData.data.id);
          matchesData.data[index] = action.payload.matchData.data
        }
      return {
        ...state,
        loadingApplication: false,
        matchData: action.payload.matchData
      };

    case SET_MATCH_ALL_RESULTS_FAILURE:
      return {
        ...state,
        loadingApplication: false,
        error: action.payload.error,
        matchesData: {}
      };


    case FETCH_ALL_MATCHES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ALL_MATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        matchesData: action.payload.matchData
      };

    case FETCH_ALL_MATCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        matchesData: {}
      };

      case FETCH_MATCH_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case FETCH_MATCH_SUCCESS:
        return {
          ...state,
          loading: false,
          matchData: action.payload.matchData
        };
  
      case FETCH_MATCH_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          matchData: {}
        };

        case FETCH_MATCH_ACTIVE_APPLICATION_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
    
        case FETCH_MATCH_ACTIVE_APPLICATION_SUCCESS:
          return {
            ...state,
            loading: false,
            matchActiveApplication: action.payload.matchActiveApplication
          };
    
        case FETCH_MATCH_ACTIVE_APPLICATION_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload.error,
            matchActiveApplication: {}
          };
  
      default:
        return state;
    }
};

export default matchesReducer;