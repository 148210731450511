import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultTeamLogo from "../images/defaultTeamLogo.png";
import { BASE_URL } from "../constants/config";
import Loader from "react-loader-spinner";
import moment from 'moment'
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {fetchTeamsApplications} from '../actions/applicationsActions'
import {fetchAllAdminLeagues} from '../actions/leaguesActions';
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';


export default function ApplicationsManagment(props) {
    const history = useHistory();
    const applications = useSelector(state => state.applications.teamsApplications.data);
    const [leagues, setLeagues] = useState([]);
    const [league, setLeague] = useState(-2);
    const loading = useSelector(state => state.applications.loading);
    const dispatch = useDispatch();
    const [onlyNew, setOnlyNew] = useState(true);
    
  

    useEffect(() => {
      _loadAsyncApplications(onlyNew, -2)
      _loadAsyncAdminLeagues()
    }, []);

    const _loadAsyncApplications = (isOnlyNew, league_id) => {
      dispatch(fetchTeamsApplications(isOnlyNew, league_id))
    }

    const _loadAsyncAdminLeagues = () => { // загрузка судей
      dispatch(fetchAllAdminLeagues()).then(
        leagues => {
          const temp_leagues = []
          leagues.data.map((item, index) => {
            item.value = item.league_id;
            item.key = index;
            item.name = item.league_id > 0 ?item.league_name + ' (' + item.championat_name + ')' :item.league_name
            temp_leagues.push(item)
          })
  
          setLeagues(temp_leagues)  
        }
    );
    };

    const toggleCheckbox = () => {
      _loadAsyncApplications(!onlyNew, league)
      setOnlyNew(state => !state)
    }

  const rendeTeamImage = (image) => {
    if(!image || (image && image == '') || (image && image == '/images/others/defaultLogo.png')){
      return (
        <img src={defaultTeamLogo} className="teamImage"/>  
      )
    } else {
      return (
        <img src={BASE_URL.url + image} className="teamImage"/>  
      )
    }
  }

  const getStatus = (status_type) => {
    switch(parseInt(status_type)){
      case 1: {
        return 'Подтверждена'
        break;
      }
      case 0: {
        return 'Отклонена'
        break;
      }
      case 3: {
        return 'Частично отклонена'
        break;
      }
      case 2: {
        return 'Ждет подтверждения'
        break;
      }
      default: {
        break;
      }
    }
  }

  const getStatusColor = (status_type) => {
    switch(parseInt(status_type)){
      case 1: {
        return 'rgba(33,152,95,0.7)'
        break;
      }
      case 0: {
        return 'rgb(253,54,71,0.7)'
        break;
      }
      case 3: {
        return 'rgb(173,255,47,0.7)'
        break;
      }
      case 2: {
        return 'rgba(0,255,255,0.4)'
        break;
      }
      default: {
        break;
      }
    }
  }

  const renderApplicationList = () => {
    console.log('app',applications)
    return applications.map((item, index) => {
      return (
        <tr onClick={()=>history.push("/manage_application/" + item.cap_application_id)} className="matchCenterRow" key={index}>
        <td className="applicationTableNumber"><div className="applicationId">{item.cap_application_id}</div></td>
        <td className="applicationTableTeam">
        <div className="applicationTeamWrapper">
              {rendeTeamImage(item.team_image)}   
              <div className="teamName">{item.team_name}</div>
            </div>
        </td>
        <td className="applicationTableApplicationName">
          <div>
            {item.application_name}
          </div>
        </td>
        <td className="applicationTableDate">{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
        <td className="applicationTableStatus">
          {getStatus(item.status)}
          </td>
          <div style={{width:'3%', height:70,backgroundColor:getStatusColor(item.status),position:'absolute',left:0}}/>
        </tr>
      )
    })
  }

  const renderContent = () => {
      if(!loading){
        if(applications && applications.length > 0){
          console.log('applications',applications)
        return (
          <div className="tableWrapper">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="applicationTableNumber">#</th>
                    <th className="applicationTableTeam">Команда</th>
                    <th className="applicationTableApplicationName">Заявка</th>
                    <th className="applicationTableDate">Дата</th>
                    <th className="applicationTableStatus">Статус</th>
                  </tr>
                </thead>
                <tbody>
                  {renderApplicationList()}
                </tbody>
              </table>
            </div>
          </div>
        )
      } else {
        return (
          <div className="emptyWrapper">
            <span className="emptyText">Похоже заявок нет...</span>
          </div>
        )
      }
    } else {

      return (
        <div className="loadingOverlay">
          <div className="loadingWrapper">
            <Loader
              type="Rings"
              color="#04a9f5"
              height={40}
              width={40}
              timeout={0}/>
            <span style={{marginLeft:10}}>Загрузка заявок</span>
          </div>
        </div>
      )
    }
  }

  const fuzzySearch = (options) => {
    const fuse = new Fuse(options, {
      keys: ['name'],
      threshold: 0.3,
  });

  return (value) => {
      if (!value.length) {
          return options;
      }
      return fuse.search(value).map(item => item.item);

  };
  }

  const changeLeague = (league) => {
    _loadAsyncApplications(onlyNew, league)
    setLeague(league)
  }




  return (
    <div className="layoutWrapper">
      <div className="matchCenterWrapper">
        <div className="matchCenterHeader">
          <div className="simpleRow" style={{justifyContent:'space-between',paddingRight:50}}>
          <span className="manageApplicationName">Заявки</span>
          <div className="matchAdditionalInfoSelectHeadingWrapper">
            <span className="matchAdditionalInfoSelectHeading">Лига</span>
            <SelectSearch
              options={leagues ?leagues :[]}
              search
              value={league}
              filterOptions={fuzzySearch}
              onChange={(x)=>changeLeague(x)}
              emptyMessage="Лига не найдена"
              placeholder="Лига"/>  
          </div>
          <div className="simpleRow">
            <span className="applicationManagmentCheckboxHint">Показывать только новые</span>
            <input
              name="isGoing"
              type="checkbox"
              checked={onlyNew}
              onChange={toggleCheckbox} />
          </div>
          </div>
        </div>
        {renderContent()}
      </div>
    </div>
  );
}