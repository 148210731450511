import {
  SEARCH_PLAYERS_BY_PARAMS_BEGIN, SEARCH_PLAYERS_BY_PARAMS_SUCCESS, SEARCH_PLAYERS_BY_PARAMS_FAILURE} from '../constants/action-types';




const initialState = {
  searchPlayers: {},
  loading: false,
  error: null
};

export default function playerReducer(state = initialState, action) {
  switch(action.type) {
    case SEARCH_PLAYERS_BY_PARAMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case SEARCH_PLAYERS_BY_PARAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchPlayers: action.payload.players
      };

    case SEARCH_PLAYERS_BY_PARAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        searchPlayers: {}
      };

    default:
      return state;
  }
}