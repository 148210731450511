import React, { useState, useEffect, useRef } from "react";
import "../styles/styles.css";
import logo from "../images/logo.png";

function Header(props) {
  // const lineRef = useRef()

  // const lineMove = () => {
  //   if (modalRef.current.classList.contains('matchResultsModalWrapperActive')) {

  //   }
  // }

  return (
    <div className="headerWrapper">
      <div className="header">
        <img src={logo} className="logo" alt="Лого" />
        <div className="btnsContainer">
          {/* <div className="menuLine" /> */}
          {/* <button className="headerBtn" onClick={() => props.setCurrentPage(0)}>
            Кнопка
          </button>
          <button className="headerBtn" onClick={() => props.setCurrentPage(1)}>
            Кнопка
          </button>
          <button className="headerBtn" onClick={() => props.setCurrentPage(2)}>
            Кнопка
          </button>
          <button className="headerBtn" onClick={() => props.setCurrentPage(3)}>
            Кнопка
          </button> */}
        </div>
        {/* если этот блок поставить, нерпавильно для телефонов работает потому что кнопку войи перекрывает надо что то для телефонов приудумать*/}
        <button onClick={props.toggleModal} className="loginButton">
          <h2 className="txtInLgnBtn">Войти</h2>
        </button>
      </div>
    </div>
  );
}

export default Header;
